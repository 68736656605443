import React, { useEffect, useState } from 'react'
import { useDispatch , connect} from 'react-redux';
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { userLogout } from '../../../redux/actions/authActions';

const Header = ({ children: children, ...props }) => {

  const [Reminders, setReminders] = useState([])

  const history = useHistory()
  const dispatch = useDispatch()
  const isAuthenticated = props.auth.isAuthenticated;

 const userEmail = props.auth.userData.email ;
 const token = props.auth.userData.token;


 const userNumber = props.auth.userData.mobile;
 const userName = props.auth.userData.username
 const totalReminders = Reminders.length
 
 const logoutHandler = () => {
   dispatch(userLogout(props.history));
  }

  function myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
  }
  
    const sideBarCloseHandler =() => {
        document.getElementById("mySidebar").style.display = "none";
        document.getElementById("main-header").style.display = "block";
        document.getElementById("home-screen").style.display = "block";
    }
  
    const sideBarOpenHandler = () => {
        document.getElementById("mySidebar").style.display = "inline-block";
        document.getElementById("main-header").style.display = "none";
        document.getElementById("home-screen").style.display = "none";
    }
    return (
    <React.Fragment>
        <div>
        <header className="main-header" id="main-header">
          <div className="container-fluid">
            <nav>
              <div className="main-header-start">
                <div className="hamburger">
                  <button
                    className="btn hamburger-btn"
                     onClick={sideBarOpenHandler}
                  >
                    <img
                      src="assets/images/icon/mainhamburger.png"
                      alt="Icon"
                      className="img-fluid"
                    />
                  </button>
                </div>
                <img
                  src="assets/images/logo/small.png"
                  alt="Logo"
                  className="img-fluid"
                />
              </div>
              <div className="main-header-end">
                <div className="search-field">
                  <form action="#">
                    <input
                      type="search"
                      placeholder="Search"
                      className="form-control"
                    />
                    <img
                      src="assets/images/icon/search.svg"
                      alt="icon"
                      className="img-fluid"
                    />
                  </form>
                </div>
                <div className="notification">
                  <button type="button" className="btn">
                    <img
                      src="assets/images/icon/clock.svg"
                      alt="Clock"
                      className="img-fluid"
                    />
                  </button>
                </div>
                <Link
                  className="nav-link avatar-area d-flex align-items-center justify-content-center"
                  to="/user"
                >
                  <div className="avatar">
                    <img
                      src="assets/images/icon/user.svg"
                      alt="User"
                      className="img-fluid"
                    />
                  </div>
                  <span>{userName}</span>
                </Link>
              </div>
            </nav>
          </div>
        </header>

        <div
          className="sidebar-area"
          style={{ display: "none" }}
          id="mySidebar"
        >
          <div className="sidebar-area-item mb-0">
            <button onClick={sideBarCloseHandler} className="btn close-btn">
              Close &times;
            </button>
          </div>
          <div className="sidebar-area-item">
            <Link to="/tags">
              <img
                src="assets/images/icon/01.png"
                alt="Icon"
                className="img-fluid"
              />{" "}
              <span>Manage tags</span>
            </Link>
          </div>
          <div className="sidebar-area-item">
            <Link to="/archives">
              <img
                src="assets/images/icon/02.png"
                alt="Icon"
                className="img-fluid"
              />{" "}
              <span>Archives</span>
            </Link>
          </div>
          <div className="sidebar-area-item">
            <Link to="/user">
              <img
                src="assets/images/icon/03.png"
                alt="Icon"
                className="img-fluid"
              />{" "}
              <span>Manage Profile</span>
            </Link>
          </div>
          <div className="sidebar-area-item">
            <Link to="/resetPassword">
              <img
                src="assets/images/icon/04.png"
                alt="Icon"
                className="img-fluid"
              />{" "}
              <span>Change password</span>
            </Link>
          </div>
          <div className="sidebar-area-item">
            <Link to="/subscription">
              <img
                src="assets/images/icon/05.png"
                alt="Icon"
                className="img-fluid"
              />{" "}
              <span>Subscription</span>
            </Link>
          </div>
          <div className="sidebar-area-item">
            <>
            <Link>
              <img
                src="assets/images/icon/06.png"
                alt="Icon"
                className="img-fluid"
              />
              <span onClick={logoutHandler}>Logout</span>
            </Link>
            </>
          </div>
        </div>

          <main>{children}</main>
        </div>
    </React.Fragment>
    );
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(Header);