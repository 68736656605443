
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import { toast } from "react-toastify";
import { IS_AUTHENTICATED  , USER_SIGNUP } from "./types";
import { api_url } from "../../utils/api";

var qs = require('qs');
import { createContext } from "react";

export const userLogin = (user) => (dispatch) => {
  axios
    .post(api_url + "/api/login", user)
    .then((res) => {
      //console.log("user =>", res.data.status);

      if (res.data.status === false) {
        return toast.error(res.data.message);
      }
      if (res.data.status === true) {
        const {
          token,
          email,
          username,
          mobile,
          n_is_subscribe,
          u_customer_id,
          n_subscribe_start_date,
          n_subscribe_end_date,
        } = res.data.payload;

        const userdata = {
          token,
          email,
          username,
          mobile,
          n_is_subscribe,
          u_customer_id,
          n_subscribe_start_date,
          n_subscribe_end_date,
        };
        //console.log("role :", res.data);
        setAuthToken(token);
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userName", username);
        localStorage.setItem("userNumer", mobile);

        dispatch({
          type: IS_AUTHENTICATED,
          payload: true,
          userData: userdata,
        });
        toast.success("Logged in successfully");
        window.location.replace("/")
      }
    })
    .catch((err) => {}
    // console.log(err)
    );
};

export const updateProfile = (values) => (dispatch) => {

  const token = localStorage.getItem("jwtToken");

  let finalData = qs.stringify({
    email : values.email,
    mobile : values.mobile,
    username : values.username
    });
console.log("values in update proifle :: ",values.mobile)
    let config = {
      method: "post",
      url: "https://dev7.invitocreatives.com//api/update-profile",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`
      },
      data: finalData,
      };

      axios
      (config)
      .then((res) => {
        console.log("response form update profile :: ",res)
        if (res.data.status === false) {
          return toast.error(res.data.message);
        }
        if (res.data.status === true) {
          const {
            token,
            email,
            username,
            mobile,
            n_is_subscribe,
            u_customer_id,
            n_subscribe_start_date,
            n_subscribe_end_date,
          } = res.data.payload;
  
          const userdata = {
            token,
            email,
            username,
            mobile,
            n_is_subscribe,
            u_customer_id,
            n_subscribe_start_date,
            n_subscribe_end_date,
          };
          //console.log("role :", res.data);
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("userName", username);
      localStorage.setItem("userNumer", mobile);

  
          dispatch({
            type: 'UPDATE_PROFILE',
            payload: true,
            userData: userdata,
          });
          toast.success(`Profile Updated SuccessFully !!`);
          window.location.replace("/")
        }
  
      })
      .catch((err) => {
        console.error(err);
      });
}


export const userSignup = (user) => (dispatch) => {
  const {
    token,
    email,
    username,
    mobile,
    n_is_subscribe,
    u_customer_id,
    n_subscribe_start_date,
    n_subscribe_end_date,
  } = user;

  const userdata = {
    token,
    email,
    username,
    mobile,
    n_is_subscribe,
    u_customer_id,
    n_subscribe_start_date,
    n_subscribe_end_date,
  };
  localStorage.setItem("jwtToken", token);
        dispatch({
          type: USER_SIGNUP,
          payload: true,
          userData: userdata,
        });
        // toast.success("Signup  successfully");
        // window.location.replace("/detail")

      
};


export const newNote = (values) => (dispatch) => {

  const token = localStorage.getItem("jwtToken");

  //console.log("Bearer token here :: ",token);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  //console.log("INSIDE DISPATCH || ACTION FILE || New Note Action");
  //console.log("Data from component :: ",values);

  axios.post(api_url+'/api/notes',
  values,
  config
  ).then((response) => {
    dispatch({
      type:'NEW_NOTE',
      payload: response,
    })
  })
  .catch((err) => {
    dispatch({
      type:'NEW_NOTE_FAIL',
      paylaod:err
    })
  })
}

export const getNotes = (values) => async(dispatch) => {
  const token = localStorage.getItem("jwtToken");
  //console.log("Bearer token here :: ",token);
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  console.log("Values from front-end :: ",values)

  // console.log("Inside getNotes Action ",values);
   const notes = await axios.post(api_url+'/api/notes-list',values,config)
  .then((response) => {
    console.log("api response from getNotes action :: ",response)
    dispatch({
      type:"NOTES_LIST",
      payload: response
    })
  })
  .catch((error) => {
    dispatch({
      type:"NOTES_LIST_FAILED",
      payload: error
    })
  })
  // dispatch({type:"NOTES_LIST",payload: notes})

  // return notes;
}

export const editNote = (values) => (dispatch) => {

  const token = localStorage.getItem("jwtToken");

  const config = {
    headers : { Authorization: `Bearer ${token}`} 
  }

  console.log("values in action :: ",values)

  axios.put(api_url+'/api/notes/'+values.id,values,config)
  .then((response) => {
    dispatch({
      type:'EDIT_NOTE_SUCCESS',
      payload:response
    })
  })
  .catch((error) => {
    dispatch({
      type:'EDIT_NOTE_FAIL',
      payload:error
    })
  })
}

export const AddNewTag = (values) => (dispatch) => {

  const token = localStorage.getItem("jwtToken");

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  //console.log("INSIDE DISPATCH || ACTION FILE || New Tag Action");
  console.log("Data from component :: ",values);

  axios.post(api_url+'/api/tags',
  values,
  config)
  .then((response) => {
    toast.success("New Tag Added !!");
    dispatch({
      type: 'NEW_TAG_ADDED',
      payload: response
    })
  })
  .catch((error) => {
    toast.error("Unable to add new Tag !!")
    dispatch({
      type: 'NEW_TAG_FAILED',
      payload: error
    })
  })

}

export const getUserTags = () => (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  return new Promise ((resolve, reject) => {
    axios.get(api_url+'/api/tags',config)
    .then(function(response){
      //console.log("REsult from api for user tags :: ",result);
      resolve(dispatch({
        type: 'USER_TAGS',
        payload: response
      }))

    })
    .catch((err) =>{
      //console.log("Error :: ",err);
      reject(dispatch({
        type: 'USER_TAGS_FAIL',
        payload: err
      }))

    })
  })

}

export const getTagNotes = (values) => (dispatch) => {
  const token = localStorage.getItem("jwtToken");

  //console.log("Bearer token here :: ",token);
  let test = '['+values+']';
  // test.push(parseInt(values));

  // console.log("Values from home component :: ",test);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  let data = {tags: test}
  console.log("Data", data);
  console.log("values vlaues :: ",values)
  axios.post(api_url+'/api/notes-list',data,config)
  .then((response) => {
    console.log("response from api :: ",response);
    dispatch({
      type:"TAG_NOTES_LIST",
      payload: response
    })
  })
  .catch((error) => {
    dispatch({
      type:"TAG_NOTES_LIST_FAILED",
      payload: error
    })
  })
}

export const acrhiveNote = (values,test) => (dispatch) => {
  const token = localStorage.getItem("jwtToken");
  console.log("values in archive note action :: ",test.component)
  const data = qs.stringify(values);
  var config = {
    method: 'put',
    url: api_url+'/api/archive',
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data : data
  };

  let isarchived;

  if(test.component === "HOME"){
    isarchived = {
      isarchived: '0'
    }
  }
  else if(test.component === "ARCHIVE"){
    isarchived = {
      isarchived: '1'
    }
  }

  axios(config).then((response) => {
    console.log("Response from archive api :: ",response);
    dispatch({
      type: 'ARCHIVE_NOTE_SUCCESS',
      payload: response
    })
    dispatch(getNotes(isarchived));
  })
  .catch((err)=>{
    //console.log("Something went wrong in archive note api !!!");
    dispatch({
      type: 'ARCHIVE_NOTE_FAIL',
      payload: err
    })
  })
  
}



export const deleteNote = (values) => (dispatch) => {
  const token = localStorage.getItem("jwtToken");


  //console.log("Bearer token :: ",token);

  //console.log("Values from component :: ",values);
  // //console.log("config value :: ",config);

  var data = qs.stringify(values);

  
  // const config = {
  //   headers: { Authorization: `Bearer ${token}` },
  //   data: data
  // };

  var config = {
    method: 'delete',
    url: 'https://dev7.invitocreatives.com/api/notes',
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    //console.log(JSON.stringify(response.data));
    dispatch({
      type:"DELETE_NOTE",
      payload: response
    })
  })
  .catch(function (error) {
    //console.log(error);
    dispatch({
      type:"DELETE_NOTE_FAIL",
      payload: error
    })
  });
}

export const ChangeFupload = (value) => (dispatch) => {

  if(value === true) {
    dispatch({
      type:"FUPLOAD_TRUE",
      payload:true
    })
  }
  else{
    dispatch({
      type:"FUPLOAD_FALSE",
      payload:false
    })
  }
  

}




export const userLogout = () => (dispatch) => {

  localStorage.removeItem("jwtToken");
  localStorage.removeItem("email");
  localStorage.removeItem("userName");
  localStorage.removeItem("userNumber");
  console.log("inside logout handler")
        dispatch({
          type: IS_AUTHENTICATED,
          payload: false,
          userData: '',
        });
        dispatch({
          type: "RESET",
        })
      }