import { CheckBox, Task } from '@mui/icons-material'
import React, { useState} from 'react'
import { Button, Form, Header, Icon, Input, Modal } from 'semantic-ui-react'
import {toast} from 'react-toastify'

const AddTask = (props) => {
  const [open, setOpen] = useState(true)
  const [ATBtoggle, setATB] = useState(false)
  const [tasks, setTasks] = useState([])
  const [NewTask, setNewTask] = useState('')
//   let tasksValue = [];

const modalCenter = {
  left: 'unset',
  marginTop: '50px',
  height: '50%'
}

const modalContent = {
  marginTop: '30px'
}

  const handleATBtoggle = () => {

      setATB(!ATBtoggle)
  }

  let testing = [...props.addedTasks];
  const handleInput = (e) => {
      e.persist();
      setNewTask(e.target.value);
  }
  const submitTasks = () => {
    testing.push({
        title: NewTask,
        isChecked: 0
      })
      if(NewTask == ''){
        toast.error("Task Should have a Name !!")
        setOpen(false)
        props.addTaskToggle(false)
      }
      else if(NewTask != ''){
        props.addNewTask(testing);
        setOpen(false)
        props.addTaskToggle(false)
      }
    console.log("Value of array after add button :: ",testing);

  }
  const handleCancel = () => {
    setOpen(false);
    props.addTaskToggle(false)
  }
  //console.log("Test for tasks value :: ",tasks)


  const addNewTask = <div>
      <Form>
            <Form.Field>
            <Input maxLength="50" type="text" onChange={handleInput} className="form-group"/>
            </Form.Field>
      </Form>
      <br/>
  </div>

  return (
    <Modal
      closeIcon
      open={open}
      size={'mini'}
      onClose={handleCancel}
      onOpen={() => setOpen(true)}
      style={modalCenter}
    >
      <Header icon='clipboard outline' content='Add Tasks for your note' />
      <Modal.Content>
        <div>
            {tasks.length > 0 && tasks.map((task)=>{
                return(
                    <div>
                        <p>{task.title}</p>
                    </div>
                )
            })}
            { ATBtoggle && addNewTask}
            { ATBtoggle ? <Button onClick={handleATBtoggle}>Cancel</Button> : <Button color="twitter" onClick={handleATBtoggle}>Add New Task +</Button>}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' onClick={handleCancel}>
          <Icon name='remove' /> Cancel
        </Button>
        <Button color='green' onClick={submitTasks}>
          <Icon name='checkmark' /> Add
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default (AddTask);