import React from 'react'
import DetailComponent from '../components/Detail/DetailComponent'
import {useHistory,Link} from 'react-router-dom'
import {toast } from "react-toastify";


const DetailPage = () => {
    // const history = useHistory()
    return (
        <div>
            <DetailComponent />
        </div>
    )
}

export default DetailPage
