import React, { useEffect, useState } from 'react'
import { useForm , Controller} from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast, ToastContainer } from 'react-toastify';
import { api_url } from '../../utils/api';
import { RedirectLogin } from '../../utils/common.js';

import axios from 'axios';
import qs from 'qs';

const ForgetPassword = () => {
  const { register, handleSubmit, control } = useForm();
  const history = useHistory();
  const [userEmailId, setEmailId] = useState()
  const [payloadid, setPayloadId] = useState()

const [UserEmailIdre, setUserEmailIdre] = useState()
    const submitHandler = (data) => {
      //  event.preventDefault();

      if(data.password !== data.cpassword){
         toast.warn("passwords doesn't match")
      }


  let finalData = qs.stringify({
    id: payloadid,
    otp: data.otp,
    password : data.password
  });

  let config = {
    method: "post",
    url: "https://dev7.invitocreatives.com/api/set-password",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: finalData,
  };


   //  //console.log("user =>" , user)
   ////console.log("id", userEmailId)

  axios
    (config)
    .then((res) => {
      toast.success(`${res.data.message}`);
      if(res.data.status == true){
        RedirectLogin(history)
      }
    })
    .catch((err) => {
     toast.error(`${err.message}`);
    });
    }

    const emailOtpHandler =(email) => {
      //console.log("data", email)
       const data = { email: email };
       //console.log("fgrt" , data)

        axios
          .post(`${api_url}/api/forgot-password`, data)
          .then((res) => {
            //console.log(res);
            toast.success(`${res.data.message}`);
            setEmailId(res.data.payload.email);
            setPayloadId(res.data.payload.id)
          })
          .catch((err) => {
            console.error("Err", err.message);
            toast.warn(`${err} || " something went wrong"}`);
          });
    }

    return (
        <div>
  <section className="starting">
    <div className="container-fluid ms-0 me-0">
      <div className="row" style={{height: "100vh"}}>
        <div className="col-lg-5 starting-bg pe-0 d-flex align-items-center justify-content-center py-4 py-lg-0"
          style={{backgroundImage: `url(assets/images/background/01.jpg)` }}>
          <div className="starting-left">
            <img src="assets/images/logo/logo.png" alt="Logo" className="img-fluid mx-auto d-block" />
            <div className="starting-left-text">
              <h6 className="text-center lead text-secondary">Keep youself manged and</h6>
              <h6 className="text-center lead text-secondary mb-0">organized</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-7 starting-right sub-pages">
          <div className="starting-right-content">
            <h2 className="text-primary text-center">Forgot password.</h2>
            <p className="normal-heading text-center mx-auto">
              You email is verified, complete your profile by adding a username and phone number (optional)
            </p>
            <div className="signup-form">
            <form onSubmit={handleSubmit(submitHandler)}>
                <div className="mb-35">
                <Controller
                          control={control}
                          name="email"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <input
                              type="email"
                              {...register("email")}
                              className="form-control custom-input"
                              placeholder="Email"
                              // onChange={(e) =>
                              //   //console.log("eee", e.target.value)
                              // }
                              onBlur={(e) => {
                                const email = e.target.value;
                                setUserEmailIdre(email)
                                emailOtpHandler(email);
                              }}
                            />
                          )}
                        />

                  </div>
                <div className="mb-3">
                  <input type="text" 
                  className="form-control custom-input" 
                  {...register("otp")}
                  placeholder="OTP" />
                  <div className="mt-2 mt-lg-3 form-text text-info text-end me-4" onClick={()=> emailOtpHandler(UserEmailIdre)}>Resend OTP</div>
                </div>
                <div className="mb-35">
                  <input type="password"
                   className="form-control custom-input"
                    placeholder="New password" 
                    {...register("password")}
                    />
                </div>
                <div className="mb-35">
                  <input type="password" 
                  className="form-control custom-input"
                   placeholder="Confirm Password" 
                   {...register("cpassword")}
                   />
                </div>
                <div className="d-flex justify-content-end mt-5 submit-area">
                  <button type="submit" className="btn-submit">Continue</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

        </div>
    )
}

export default ForgetPassword
