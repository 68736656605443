import React from 'react'
import ResetPasswordComponent from '../components/ResetPassword/ResetPasswordComponent'

const ResetPasswordPage = () => {
    return (
        <div>
            <ResetPasswordComponent />
        </div>
    )
}

export default ResetPasswordPage
