import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { connect, useDispatch,useSelector } from 'react-redux';
import { userLogout, getNotes, deleteNote, getUserTags, getTagNotes, acrhiveNote } from '../../redux/actions/authActions';
import moment from "moment";
import {Button, Checkbox, Segment, Loader, Dimmer, Grid, TransitionablePortal, Confirm, Reveal, Image} from 'semantic-ui-react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { toast } from 'react-toastify'
import axios from 'axios';
import { css } from 'glamor'
import SecondHeaderComponent from '../common/header/header2'
const api_url = "https://dev7.invitocreatives.com";



const token = localStorage.getItem("jwtToken");
const config = {
  headers: { Authorization: `Bearer ${token}` }
};



const ArchievesComponent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [getUsrNotes, setUsrNotes] = useState([]);
  const [Loading, setLoading] = useState(true);
  let [pageNumber, setPageNumber] = useState(0)
  let [showLoadMore, setShowLoadMore] = useState(false)
  let [Open, setOpen] = useState(false)
  let [ArchiveOpen, setArchiveOpen] = useState(false)
  let [DeleteNoteValue, setDeleteNoteValue] = useState('')
  let [ArchiveNoteValue, setArchiveNoteValue] = useState('')
 

  const modalCenter = {
    left: 'unset',
    marginTop: '180px',
    height: '22%'
  }

  //console.log('getUsrNotes',getUsrNotes)
// const filteredNotes = props.result.filter((notes) => notes.n_isarchive == "1");


  console.log("Props in archive component :: ",props);
  const logoutHandler = () => {
    //console.log("ho loggog")
    dispatch(userLogout(props.history));
   }

   const delNote = param => e => {
    setOpen(false)
    const ids = {
      'ids': [param]
    }
    dispatch(deleteNote(ids))
    toast.success("Note Deleted !!");
    if(props.auth.auth.result.filter((notes)=> notes.n_uuid === param)){
      setUsrNotes(getUsrNotes.filter((notes) => notes.n_isarchive == "1" && notes.n_uuid !== param))
    }
  }

  let deleteConfirmShow = param => e => {
    setDeleteNoteValue(param)
    console.log("value of param :: ",param);
    setOpen(true);
  }
  console.log("value of DeleteNoteValue :: ",DeleteNoteValue)
  let deleteConfirm = <Confirm 
  content='Do You want to Delete this Note ?'
  style={modalCenter}
   open={Open}
    onCancel={() => setOpen(false)}
    onConfirm={DeleteNoteValue != undefined ? delNote(DeleteNoteValue) : toast.error("Unable to fetch Tag ID !!!")}
     />
  
 


  const handleLoadMore = () => {
    setLoading(true)
    let page = {
      page: JSON.stringify(pageNumber),
      isarchive: '1'
    }
    axios.post(api_url+'/api/notes-list',page,config)
    .then((response) => {
      console.log("get user notes in useEffects :: ",response.data.payload.data)
        console.log("api response from getNotes action :: ",response)
        setUsrNotes((values)=> ([
          ...values.concat(response.data.payload.data.filter((notes)=> notes.n_isarchive == "1"))
      ]))
        // setReminders(response.data.payload.data.filter((notes) => notes.n_reminder != null))
        setLoading(false)
    setPageNumber(pageNumber + 1)
      if(response.data.payload.data.length <= 0){
        setShowLoadMore(false)
      }
      })
      .catch((error) => {
        setUsrNotes([{
          messgae: 'No Notes Found !!!'
        }]);
      })
  }

  const ArchiveNote = param => e => {
    setArchiveOpen(false)

    const ids = {
      'ids': [param]
    }

    const from = {
      component: 'ARCHIVE'
    }

    dispatch(acrhiveNote(ids,from))
    toast.success("Note UnArchived !!");
    if(props.auth.auth.result.filter((notes)=> notes.n_uuid === param)){
      setUsrNotes(getUsrNotes.filter((notes) => notes.n_uuid !== param))
    }
  }

  let ArchiveConfirmShow = param => e => {
    setArchiveNoteValue(param)
    setArchiveOpen(true);
  }
  let ArchiveConfirm = <Confirm 
  content='Do You want to UnArchive this Note ?'
   style={modalCenter}
    open={ArchiveOpen}
     onCancel={() => setArchiveOpen(false)}
      onConfirm={ArchiveNoteValue != undefined ?  ArchiveNote(ArchiveNoteValue) : toast.error("Unable to fetch Note ID")} 
      />

  useEffect(()=>{

    if(token == 'undefined'){
      console.log("inside first token check !!!")
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
    }
     else if(token == null){
      console.log("inside second token check !!!")
  
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
     } else if(token == undefined){
      console.log("inside third token check !!!")
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
    }
    else if(token == ''){
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname:'/login'})
    }

    // getNotesResult()

    let page = {
      page: JSON.stringify(pageNumber),
      isarchive: '1'
    }

    axios.post(api_url+'/api/notes-list',page,config)
    .then((response) => {
    console.log("get user notes in useEffects :: ",response.data.payload.data)
    console.log("api response from getNotes action :: ",response)
    setUsrNotes(response.data.payload.data.filter((notes)=> notes.n_isarchive == "1"));
    setLoading(false)
    setPageNumber(pageNumber + 1)
    if(response.data.payload.data.length > 9){
      setShowLoadMore(true)
    }
  })
  .catch((error) => {
    setUsrNotes([{
      messgae: 'No Notes Found !!!'
    }]);
  }) 
},[])
console.log("user notes in archive page :: ",getUsrNotes)

    return (
      <div>
        {/* <!-- Header Starts Here --> */}
        <SecondHeaderComponent>

        {/* <!-- Archives Area Starts Here --> */}
        <section className="note-area archives-area mt-0">

        {Loading ? <>
          <Dimmer active inverted>
                <Loader size='large'>Loading</Loader>
                </Dimmer>
          <div className="container">
            <div
              className="row user-profile justify-content-center"
              style={{
                backgroundImage: `url(assets/images/background/02.jpg)`,
              }}
            >
              <div className="col-lg-12">
                <h4>Archives</h4>
                <div className="row">
                <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
                    { getUsrNotes.length > 0 && getUsrNotes.map((value) =>{
                        return( 
                    <li style={{listStyle:'none'}} key={value.id}>
                      <div className="ui card" 
                               style={{borderRadius: '25px',padding: '10px 0px 0px 0px'}}>
                        <div className="content" onClick={() =>{ history.push({
                               pathname:`edit/${value.n_uuid}`,
                               state:{
                                 NoteData:value
                               }
                              }) }}>
                          <div className="header"><h2 style={{color:'#042C3E'}}>{value.n_title}</h2></div>
                          <div className="description" style={{padding:'10px 0px'}}>
                          {value.n_description ? value.n_description.length > 65 ? <div dangerouslySetInnerHTML={{ __html: value.n_description.replace(/(<([^>]+)>)/ig, '').substr(0,150)+' ...' }} /> : <div dangerouslySetInnerHTML={{ __html: value.n_description }} /> : <div dangerouslySetInnerHTML={{ __html: value.n_description }} />}
                          <div className="ui cards" style={{paddingTop:'10px',justifyContent:'center'}}>
                            
                            {value.notes_images.length > 1 ? (value.notes_images.map((image, init)=>{
                              return(
                                <Reveal key={init} animated='move' >
                                  {init == 0 && <> <Reveal.Content visible>
                                  <Image size='small' loading="lazy" src={image.ni_url} alt="user_image" style={{border:'1px solid #2C394E',marginTop:'8px'}} />
                                </Reveal.Content>
                                <Reveal.Content hidden>
                                  <h5 style={{padding:'45px 0px',display:'flex',justifyContent:'center'}} >{value.notes_images.length - 1} More</h5>
                                </Reveal.Content> </> }
                                </Reveal>
                                
                              )
                            })) : (value.notes_images.map((image, init)=>{
                              return(
                                <div key={init}>
                                <Reveal.Content visible>
                                  <Image size='small' loading="lazy" src={image.ni_url} alt="user_image" style={{border:'1px solid #2C394E',marginTop:'8px'}} />
                                </Reveal.Content>
                                <Reveal.Content hidden>
                                  {/* <h5>{value.notes_images.length} More</h5> */}
                                </Reveal.Content> 
                                  </div>
                              )
                            })) }
                          </div>
                          <div style={{marginTop:'18px'}}>
                            {value.notes_tags.map((tags,indexes)=>{
                              return(
                                <button className="ui inverted button"
                                 style={{marginTop:'2px',borderRadius:'18px',backgroundColor:'#93D9F8'}}
                                >{tags.tag.tg_title}</button>
                              )
                            })}
                            </div>
                            <div style={{marginTop:'18px',overflow:'hidden'}}>
                              {value.notes_tasks.map((task,Index)=>{
                                return (<>
                                  <Checkbox key={Index} checked={task.nta_ischecked} label={task.nta_title} disabled/>
                                  <br/>
                                </>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="extra content">
                        <div className="left floated author">
                          <div>
                          <button className="ui inverted secondary button icon"
                             onClick={()=>{ history.push({
                              pathname:`edit/${value.n_uuid}`,
                              state:{
                                NoteData:value
                              }
                             }) }}
                              value={value.n_uuid}
                              style={{borderRadius:'46%'}}
                              >
                                <i className="pencil alternate icon"></i>
                                </button>
                                <button className="ui inverted secondary button icon"
                                onClick={deleteConfirmShow(value.n_uuid)}
                                style={{borderRadius:'46%'}}
                                >
                                  <i class="trash icon"></i>
                                </button>
                                <button className="ui inverted secondary button icon"
                              onClick={ArchiveConfirmShow(value.n_uuid)}
                              style={{borderRadius:'46%'}}
                              >
                                <i className="archive icon"></i>
                                </button>
                          </div>
                          </div>
                          <div className="right floated author">
                          {moment(value.updatedAt).format('MMMM DD YYYY')}
                          </div>
                        </div>
                      </div>
                    </li>
                  )})
              }
                </Masonry>
                {deleteConfirm}
                {ArchiveConfirm}
                </div>
              </div>
            </div>
          </div>
        </> : <>
        <div className="container">
            <div
              className="row user-profile justify-content-center"
              style={{
                backgroundImage: `url(assets/images/background/02.jpg)`,
              }}
            >
              <div className="col-lg-12">
                <h4>Archives</h4>
                <div className="row">
                <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
                    { getUsrNotes.length > 0 && getUsrNotes.map((value) =>{
                        return( 
                    <li style={{listStyle:'none'}} key={value.id}>
                      <div className="ui card" 
                               style={{borderRadius: '25px',padding: '10px 0px 0px 0px'}}>
                        <div className="content" onClick={() =>{ history.push({
                               pathname:`edit/${value.n_uuid}`,
                               state:{
                                 NoteData:value
                               }
                              }) }}>
                          <div className="header"><h2 style={{color:'#042C3E'}}>{value.n_title}</h2></div>
                          <div className="description" style={{padding:'10px 0px'}}>
                          {value.n_description ? value.n_description.length > 65 ? <div dangerouslySetInnerHTML={{ __html: value.n_description.replace(/(<([^>]+)>)/ig, '').substr(0,150)+' ...' }} /> : <div dangerouslySetInnerHTML={{ __html: value.n_description }} /> : <div dangerouslySetInnerHTML={{ __html: value.n_description }} />}
                          <div className="ui cards" style={{paddingTop:'10px',justifyContent:'center'}}>
                            
                            {value.notes_images.length > 1 ? (value.notes_images.map((image, init)=>{
                              return(
                                <Reveal key={init} animated='move' >
                                  {init == 0 && <> <Reveal.Content visible>
                                  <Image size='small' loading="lazy" src={image.ni_url} alt="user_image" style={{border:'1px solid #2C394E',marginTop:'8px'}} />
                                </Reveal.Content>
                                <Reveal.Content hidden>
                                  <h5 style={{padding:'45px 0px',display:'flex',justifyContent:'center'}} >{value.notes_images.length - 1} More</h5>
                                </Reveal.Content> </> }
                                </Reveal>
                                
                              )
                            })) : (value.notes_images.map((image, init)=>{
                              return(
                                <div key={init}>
                                <Reveal.Content visible>
                                  <Image size='small' loading="lazy" src={image.ni_url} alt="user_image" style={{border:'1px solid #2C394E',marginTop:'8px'}} />
                                </Reveal.Content>
                                <Reveal.Content hidden>
                                  {/* <h5>{value.notes_images.length} More</h5> */}
                                </Reveal.Content> 
                                  </div>
                              )
                            })) }
                          </div>
                          <div style={{marginTop:'18px'}}>
                            {value.notes_tags.map((tags,indexes)=>{
                              return(
                                <button className="ui inverted button"
                                 style={{marginTop:'2px',borderRadius:'18px',backgroundColor:'#93D9F8'}}
                                >{tags.tag.tg_title}</button>
                              )
                            })}
                            </div>
                            <div style={{marginTop:'18px',overflow:'hidden'}}>
                              {value.notes_tasks.map((task,Index)=>{
                                return (<>
                                  <Checkbox key={Index} checked={task.nta_ischecked} label={task.nta_title} disabled/>
                                  <br/>
                                </>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="extra content">
                        <div className="left floated author">
                          <div>
                          <button className="ui inverted secondary button icon"
                             onClick={()=>{ history.push({
                              pathname:`edit/${value.n_uuid}`,
                              state:{
                                NoteData:value
                              }
                             }) }}
                              value={value.n_uuid}
                              style={{borderRadius:'46%'}}
                              >
                                <i className="pencil alternate icon"></i>
                                </button>
                                <button className="ui inverted secondary button icon"
                                onClick={deleteConfirmShow(value.n_uuid)}
                                style={{borderRadius:'46%'}}
                                >
                                  <i class="trash icon"></i>
                                </button>
                                <button className="ui inverted secondary button icon"
                              onClick={ArchiveConfirmShow(value.n_uuid)}
                              style={{borderRadius:'46%'}}
                              >
                                <i className="archive icon"></i>
                                </button>
                          </div>
                          </div>
                          <div className="right floated author">
                          {moment(value.updatedAt).format('MMMM DD YYYY')}
                          </div>
                        </div>
                      </div>
                    </li>
                  )})
              }
                </Masonry>
                {deleteConfirm}
                {ArchiveConfirm}
                </div>
              </div>
            </div>
          </div>
        </>}
        <div className="col-lg-12" style={{}}>
                <div className="row">
                    <div className="d-flex justify-content-center justify-content-lg-center">
                    {showLoadMore ? <Button onClick={handleLoadMore} style={{borderRadius:'20px',marginBottom:'30px'}} inverted color="secondary">Load More</Button> : null }
                    </div>
                </div>
                </div>
        </section>
        </SecondHeaderComponent>
      </div>
    );
}

const mapStateToProps = state => ({
  auth: state
})

export default connect(mapStateToProps)(ArchievesComponent);
