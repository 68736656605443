import React from 'react'
import ForgetPassword from '../components/ForgetPassword/ForgetPassword'

const ForgotPasswordPage = () => {
    return (
        <div>
            <ForgetPassword />
        </div>
    )
}

export default ForgotPasswordPage
