import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { store } from './store'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'
import './assets/css/vendor/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getNotes } from './redux/actions/authActions';
import './assets/css/style.css';


// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
