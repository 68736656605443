import { IS_AUTHENTICATED , USER_SIGNUP, NEW_NOTE,
  NEW_NOTE_FAIL, USER_TAGS, USER_TAGS_FAIL, NEW_TAG_ADDED, NEW_TAG_FAILED, NOTES_LIST, NOTES_LIST_FAILED, DELETE_NOTE, DELETE_NOTE_FAILED, TAG_NOTES_LIST, TAG_NOTES_LIST_FAILED, ARCHIVE_NOTE_SUCCESS, ARCHIVE_NOTE_FAIL,RESET, UPDATE_PROFILE } from "../actions/types";

const initialState = {
 isAuthenticated: false,
 userRole : 'none',
 authenticated : false,
 result: [],
 tags: []
};

export default function (state = initialState, action) {
 switch (action.type) {
   case IS_AUTHENTICATED:
     return {
       ...state,
       isAuthenticated: action.payload,
       userData: action.userData,
       authenticated: action.isAuth,
     };
     // case IS_AUTHENTICATED:
     // return {
     //   ...state,
     //   isAuthenticated: action.payload,
     //   userData: action.userData,
     //   authenticated: action.isAuth,
     // };
     case USER_SIGNUP:
     return {
       ...state,
       userData: action.userData,
     };
     case UPDATE_PROFILE:
       return {
         ...state,
         userData: action.userData
       }
     case NEW_NOTE:
       return {
         ...state,
         newNote: action.payload
       }
       case NEW_NOTE_FAIL:
         return {
           ...state,
           newNote: action.payload
         }
       case USER_TAGS:
         return {
           ...state,
           tags: action.payload && action.payload.data && action.payload.data.payload || []
         }
       case USER_TAGS_FAIL:
         return{
           ...state,
           tags: action.payload
         }
       case NEW_TAG_ADDED:
         return{
           ...state,
           tags: action.payload && action.payload.data && action.payload.data.payload || []
         }
       case NEW_TAG_FAILED:
         return{
           ...state,
           tags: action.payload
         }
       case NOTES_LIST:
         return{
           ...state,
           result:  action.payload && action.payload.data && action.payload.data.payload && action.payload.data.payload.data || []
         }
       case NOTES_LIST_FAILED:
         return{
           ...state,
           result: action.payload
         }
       case DELETE_NOTE:
         return {
           ...state,
           delete: action.payload
         }
       case DELETE_NOTE_FAILED:
         return {
           ...state,
           delete: action.payload
         }
       case TAG_NOTES_LIST:
         return{
           ...state,
           result: action.payload && action.payload.data && action.payload.data.payload && action.payload.data.payload.data || []
         }
       case TAG_NOTES_LIST_FAILED:
         return{
           ...state,
           result : action.payload
         }
       case ARCHIVE_NOTE_SUCCESS:
         return{
           ...state,
           response: action.payload
         }
       case ARCHIVE_NOTE_FAIL:
         return{
           ...state,
           response: action.payload
         }
       case RESET:
         return{
           initialState
         }
   default:
     return state;
 }
}