import React from 'react'
import NoteScreenComponent from '../components/NotesScreen/NoteScreenComponent'

const NotesScreenPage = () => {
    return (
        <div>
            <NoteScreenComponent />
        </div>
    )
}

export default NotesScreenPage
