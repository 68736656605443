import React, { useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { connect, useDispatch } from 'react-redux';
import { ChangeFupload } from '../../redux/actions/authActions';
import S3FileUpload, { uploadFile, deleteFile } from "react-s3";
import { toast } from 'react-toastify';


// const config = {
//   bucketName: "s3yeti",
//   dirName: "RankedImages",
//   region: "ap-south-1",
//   accessKeyId: " AKIAWMQBRPCBP34UWJOK",
//   secretAccessKey: "aYwZSPZYffI4jFdmVehYSh1tD5WyFOXnHpJZ1vX",
// };

const config = {
  bucketName: "yellowishs3bucket",
  dirName: "NoteYeti",
  region: "ap-south-1",
  accessKeyId: "AKIAWMQBRPCBP4XTBPTL",
  secretAccessKey: "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"
}

const FileUpload = (props) => {
    const [file, setFile] = useState([]);
    const [open, setOpen] = useState(true)

    const dispatch = useDispatch();

    const modalCenter = {
      left: 'unset',
      marginTop: '10px',
      height: '100%',
    }
    const modalContent = {
      marginTop: 'inherit'
    }

  async function uploadSingleFile(e) {
    let response_s3 = await S3FileUpload
    .uploadFile(e.target.files[0], config);
    console.log("response from s 3 bucket :: ",response_s3)
    
    setFile(response_s3.location)
    props.setFiles([...props.userFiles, response_s3.location]);
    // console.log("value in files array :: ",file);
    // .then(data => console.log(data))
    // .catch(err => console.error(err))
    //console.log("file", file);
    if(props.userFiles.length > 3){
      toast.warn("Maximum images to select are 4")
    }
  }

  function handleUpload(e) {
    console.log("length of files state in file upload component :: ",file.length)
    props.fUploadToggle(false);
    setOpen(false)
    //console.log(file);
  }
  function handleCancel(){
    props.setFiles([...props.userFiles])
    props.fUploadToggle(false);
    setOpen(false)
  }

  function deleteFile(e) {
    const s = props.userFiles.filter((item, index) => index !== e);
    props.setFiles(s);
    S3FileUpload
    .deleteFile(props.userFiles, config)
    .then(response => console.log(response))
    .catch(err => console.error(err))
    //console.log(s);
  }
    return( 
      <div className="conatiner">
      <Modal
      centered={false}
      size={'mini'}
      closeIcon
      open={open}
      onClose={handleCancel}
      onOpen={() => setOpen(true)}
      style={modalCenter}
    >
      <Modal.Content style={modalContent}>
         
    <form>
        <div className="ui two doubling cards">
        {props.userFiles.length > 0 &&
          props.userFiles.map((item, index) => {
            return (
               <div className="card">
              <div key={item} className="image">
                <img src={item} alt="" />
                <br/>
                <Button className="input-group inverted bg-danger" type="button" onClick={() => deleteFile(index)}>
                  Delete
                </Button>
              </div>
              </div>
            );
          })}
      </div>

      <div className="form-group">
        <input
          type="file"
          disabled={props.userFiles.length === 4}
          className="form-control"
          onChange={uploadSingleFile}
        />
      </div>
      <br/>
      <Modal.Actions>
        <Button color='red' onClick={handleCancel}>
          <Icon name='remove' />Cancel
        </Button>
        <Button color='green' onClick={handleUpload}>
          <Icon name='checkmark' /> Upload
        </Button>
      </Modal.Actions>
    </form>
    </Modal.Content>
    </Modal>
    </div>
    )
}

export default (FileUpload);