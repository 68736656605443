import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Checkbox, List,Grid, Segment, TransitionablePortal } from 'semantic-ui-react';
import { connect, useDispatch } from 'react-redux';
import { newNote, getUserTags, AddNewTag } from '../../redux/actions/authActions';
import moment from "moment";
import 'moment-timezone'
import axios from 'axios'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { ToastContainer, toast } from "react-toastify";
import S3FileUpload, { uploadFile, deleteFile } from "react-s3";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ReactQuill from 'react-quill'; 
import FileUpload from '../FileUploadComponent/fileUpload';
import AddTag from '../AddTagComponent/addTag';
import 'react-quill/dist/quill.snow.css';
import SelectDate from '../selectDatecomponent/selectDateComponent';
import AddTask from '../addTaskComponent/addTask';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import SecondHeaderComponent from '../common/header/header2'

const config = {
  bucketName: "yellowishs3bucket",
  dirName: "NoteYeti",
  region: "ap-south-1",
  accessKeyId: "AKIAWMQBRPCBP4XTBPTL",
  secretAccessKey: "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"
}

const api_url = "https://dev7.invitocreatives.com";
const token = localStorage.getItem("jwtToken");
const configure = {
  headers: { Authorization: `Bearer ${token}` }
};

const NewNoteComponent = (props) => {
  const buttonStyle = {
    backgroundColor: '#93D9F8',
    border: '2px solid black',
    padding: '10px',
    margin:'0px 25px',
    height: '40px',
    width: '90px',
    borderRadius: '20px',
    color:'white'
  }

  let guessZone = moment.tz.guess();
  const history = useHistory()

  const dispatch = useDispatch();
  
  const [getUsrTags, setUsrtags] = useState([]);
  const [values, setValues] = useState({
    title: '',
    description: '',
    QuillLength: 0
  })
  const [checkB, setCheck] = useState(false);
  const [Tasks, setTasks] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [dateselectbtn, setbtnvalue] = useState(false);
  const [tagsBtn, setTagBtn] = useState(false);
  const [tagsValue , setTagsValue] = useState([]);
  let [Fupload,setFupload] = useState(false);
  let [UserFile, setUserFile] = useState([]);
  let [taskIsChecked, setTaskIsChecked] = useState(0)
  let [reminderCycle, setReminderCycle] = useState(0)


//handle functions for input
  const handleTitleInput = (e) => {
    setValues((values) => ({
      ...values,
      title: e.target.value
    }))
  }

  console.log("value of location :: ",location);

  const handleDescInput = (e,Delta,Source,UnprivilegedEditor) => {
    console.log('Quill',e);
    console.log('Quill length',UnprivilegedEditor.getLength());
    let editorLength = UnprivilegedEditor.getLength();
    if(editorLength <= 1200){
      setValues((values) => ({
        ...values,
        description: e,
        QuillLength: UnprivilegedEditor.getLength()
      }))
    }else{
      setValues((values)=>({
        ...values,
        QuillLength: UnprivilegedEditor.getLength()
      }))
      toast.error('Notes max length is 1200');
    }
    
  }

  function deleteFile(e) {
    const s = UserFile.filter((item, index) => index !== e);
    setUserFile(s);
    S3FileUpload
    .deleteFile(UserFile, config)
    .then(response => console.log(response))
    .catch(err => console.error(err))
  }

  const handleCheck = (e) => {
    e.persist();
    setCheck(!checkB);
  }

  function deleteTask(e){
    let remaining = Tasks.filter((item,index)=> index !== e)
    setTasks(remaining);
  }

  const handleCheckbox = (e) => {
    if(taskIsChecked == 0){
      setTaskIsChecked(1)
    }
    else if(taskIsChecked == 1){
      setTaskIsChecked(0)
    }
    let updateTasks = [...Tasks]
    let objectindex = Tasks.findIndex(((obj,index) => index == e));
    let isTaskChecked = taskIsChecked
    updateTasks[objectindex].isChecked = isTaskChecked;
    setTasks(updateTasks);
  }

  const tasksList = <List divided verticalAlign='middle' style={{padding:'5px 30px'}}>
    {Tasks.length > 0 && Tasks.map((task,index)=>{
      return(
        <List.Item key={index} >
      <List.Content>
        <Checkbox checked={task.isChecked} label={task.title} onClick={()=>handleCheckbox(index)} />
        <Fab size="small" onClick={()=>deleteTask(index)} style={{background:'unset',boxShadow:'unset'}}>
        <DeleteIcon style={{color:'red'}}/>
        </Fab>
      </List.Content>
    </List.Item>
      )
    })}
  </List>

  const selectDate = () => {
    setbtnvalue(!dateselectbtn);
  }

  //tags toggle button
  const tagbtntoggle = (e) => {
    e.preventDefault();
    setTagBtn(!tagsBtn);
  }




  const handleTagsValue = (e) => {
   let tgsValue = e.map((tgvalues, index)=>{
      return{
        id:tgvalues.id,
        value:tgvalues.value,
      }
    })
      setTagsValue(tgsValue)
  }
  //adding tags value to state ends here --

  //Remove tags from selected tags

  const getCloseId = params => e => {
    let elementId = e.target.id;
    let element = e.target.value;

    let newTagsValue = tagsValue.filter((tag) => tag.id !== params);
    console.log("elementID :: ",e);
    console.log("value of newTagsValue :: ",newTagsValue)
    setTagsValue(newTagsValue);
    toast.error("Tag removed");

    <div className="d-inline-flex" style={{display:'none'}} key={elementId}>{element}</div>
  }
  console.log("Tags Value :: ",tagsValue);
  //Remove tags from selected tags ends here  --

  //selected from dropdown tags 
  let valuefromTgArr = 
    <Stack direction="row" spacing={1}>
  {tagsValue.map((data,index) => {
      return (
        <Chip
        label={data.value}
        value={data.value}
        key={index}
        variant="outlined" 
        color="info"
        onDelete={getCloseId(data.id)}
      />
      )
    })}
    </Stack>
    //selected from dropdown tags ends here --

    //tags in state 
    const selectedTagsID = tagsValue.map((tag) =>{
      return {
        id: tag.id
      }
    })

    let tags = JSON.stringify(selectedTagsID);

    //tasg in state ends here

  //file upload switch 
  let FuploadBtn = (e) => {
    e.preventDefault();
    setFupload(!Fupload)
  }
  //file upload switch ends here -- 

  let selectedImages = UserFile.map((item)=>{
    return{
      url: item
    }
  })

  let images = JSON.stringify(selectedImages)

  let userAddedTasks = Tasks.map((value)=>{
    return{
      title:value.title,
      ischecked:value.ischecked
    }
  })

  let tasks = JSON.stringify(userAddedTasks)

  let showReminder = '';
  if(reminderCycle == 0){
    showReminder = 'One Time'
  }
  else if(reminderCycle == 1){
    showReminder = 'Daily'
  }
  else if(reminderCycle == 2){
    showReminder = 'Weekly'
  }
  else if(reminderCycle == 3){
    showReminder = 'Monthly'
  }
  else if(reminderCycle == 4){
    showReminder = 'Yearly'
  }
  else{
    showReminder = ''
  }
  console.log("value of showReminder :: ",showReminder)



console.log("value of selected date :: ",startDate);
    //data sent
  const handleSubmit = (e) => {
    e.preventDefault();
    
    let reminder = '';

    if(startDate != ''){
      console.log("date selected :: ",startDate)
      let momentDate = moment(startDate).format()
      console.log("value of moment DAte :: ",momentDate)
      let testDate = startDate
      let testsplit = testDate.split("T");
      if(testsplit.length > 0){
        let testsplittime = testsplit[1].split(',');
        let testfortime = testsplittime[0].split('.')
        let testsecondsplit = testfortime[0].split(':')
        let dateAndTime = testsecondsplit[0]+':'+testsecondsplit[1]
        console.log("value of test split time :: ",moment(testsplittime).tz(guessZone).format('LT'))
        console.log("value of test for time :: ",dateAndTime)
        reminder = '[{"date":"'+testsplit[0]+'","time":"'+dateAndTime+'","cycle":"'+reminderCycle+'"}]';
        console.log("value of reminder in submit handler :: ",reminder);
      }
      else{
        reminder = 'Unable to fetch data !!'
      }
    }
    else{
      reminder = ''
    }

    if(values.title == '' || values.title == null){
      toast.error("Title is Required !!!");
      console.log("length of quill length :: ",values.QuillLength)
    }
    else if(values.QuillLength > 1200){
      console.log("length of quill length :: ",values.QuillLength)
      toast.error("Note Description Length should be less than 1200 words !!!");
    }
    else if(values.QuillLength <= 1200){
      console.log("length of quill length :: ",values.QuillLength)
      if(values.title != ''){
        const noteData = {
          ...values,
          reminder,
          images,
          tags,
          tasks
        }
        dispatch(newNote(noteData));
        setValues(()=>({
          title: '',
          description: ''
        }))
        setStartDate('')
        setUserFile([])
        reminder = ''
        images = ''
        setTagsValue([])
        tags = ''
        setTasks([])
        tasks = ''

        setTimeout(()=>{
          history.push({pathname: '/'})
        },200)
      }
      else{
        toast.error("Note Description Length should be less than 1200 words !!!");
      }
    }    
  }
  console.log("value of react quill length outside submit function :: ",values.QuillLength)
//data sent ends here

const imageStyleDiv = {
  height: '200px',
    overflow: 'hidden',
    borderRadius: '10px'
} 
const imageStyle = {
  height: '100%',
  width:'auto'
}
const buttonDiv = {
  position: 'absolute',
  bottom: '10px',
  right:'10px'
}

useEffect(() => {

  if(token == 'undefined'){
    console.log("inside first token check !!!")
    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname: '/login'})
  }
   else if(token == null){
    console.log("inside second token check !!!")

    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname: '/login'})
   } else if(token == undefined){
    console.log("inside third token check !!!")
    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname: '/login'})
  }
  else if(token == ''){
    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname:'/login'})
  }
  setUsrtags(props.auth.tags)
  console.log("props of new note component :: ",props);


},[props.auth.tags])
console.log("getUserTags :: ",getUsrTags);

  
    return (
        <div>

             {/* <!-- Header Starts Here --> */}
             <SecondHeaderComponent>
  {/* <!-- Header Ends Here --> */}
            
  {/* <!-- Note Area Starts Here --> */}
  <section className="note-area">
    <div className="container">
            {tagsBtn && <AddTag fromEditNote={false} currentSelected={tagsValue} addTagToggle={setTagBtn} usrTags={getUsrTags} selectedTags={setTagsValue} />}
            {Fupload && <FileUpload fUploadToggle={setFupload} setFiles={setUserFile} userFiles={UserFile}/>}
            {dateselectbtn && <SelectDate reminderC={setReminderCycle} dateSelectToggle={setbtnvalue} setDate={setStartDate} currentDate={startDate} /> }
            {checkB && <AddTask addTaskToggle={setCheck} taskChecked={taskIsChecked} addNewTask={setTasks} addedTasks={Tasks} />}
      {NewNoteComponent ? null : <div className="row">
        <div className="col-lg-12">
          <div className="note-area-heading">
            <div className="mt-0 mt-lg-4">
              
              {/* <!-- <span className="sub-heading">Desciption</span> --> */}
            </div>
            <div className="note-area-right d-flex align-items-center mt-0">
              <button type="button" className="btn">
                <img src="assets/images/icon/share.svg" alt="Icon" className="img-fluid" />
                <div className="tooltip-text">
                  <span>Share</span>
                </div>
              </button>
              <button type="button" className="btn">
                <img src="assets/images/icon/file.svg" alt="Icon" className="img-fluid" /> 
                <div className="tooltip-text">
                  <span>Archive</span>
                </div>
              </button>
              <button type="button" className="btn">
                <img src="assets/images/icon/trash.svg" alt="Icon" className="img-fluid" />
                <div className="tooltip-text">
                  <span>Delete</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      }
      <div className="row note-area-content">
      <div><div className="col-lg-12">
      {startDate !== '' ? <><img src="assets/images/icon/clock.svg" alt="Icon" style={{height:'30px',width:'30px'}} className="img-fluid" /> <span style={{Left: '25px'}}>Reminder Set For - {JSON.stringify(moment(startDate).format('MMMM Do YYYY, h:mm:ss a'))} with {showReminder} Reminder. </span> </> : null}
        {valuefromTgArr}
        <form action="#" className="title-text">
                <textarea
                 placeholder="Title"
                  name="Title"
                   value={values.title}
                    className="form-control"
                    onChange={handleTitleInput}
                    maxLength="30"
                    >
                      </textarea>
                    
              </form>
          <form action="#" className="desciption-text">
          <ReactQuill
             placeholder="Desciption"
              name="Description"            
               value={values.description}
                className="form-control"
                onChange={handleDescInput}
                />
          </form>
        </div> 
        </div> 
        {tasksList}
                <div className="ui six doubling cards">
                  
        {UserFile.length > 0 &&
          UserFile.map((item, index) => {
            return (
               <div style={{borderRadius:'10px'}} className="card">
              <div style={imageStyleDiv}  key={item} className="image imageStyleDiv">
                <img style={imageStyle} src={item} alt="" />
                
              </div>
              <div style={buttonDiv}>
              <Fab  size="small" onClick={()=>deleteFile(index)} style={{float:'right',backgroundColor:'Crimson'}} color="primary" aria-label="add">
                  <DeleteIcon />
                </Fab>
                </div>
              </div>
            );
          })}
      </div>
      </div>

      <div className="row" style={{marginTop:'20px'}}>
        <div className="col-lg-12">
          <div className="note-area-footer d-flex align-items-center">
            <button type="button" onClick={handleCheck} className="btn"><img src="assets/images/icon/tick.svg" alt="Icon" className="img-fluid" /></button>
            
            <button type="button" onClick={FuploadBtn} className="btn"><img src="assets/images/icon/image.svg" alt="Icon" className="img-fluid" />
            </button>
            <button type="button" className="btn"  onClick={tagbtntoggle}><img src="assets/images/icon/save.svg" alt="Icon" className="img-fluid" /></button>
            <button type="button" className="btn" onClick={selectDate}>
              <img src="assets/images/icon/clock.svg" alt="Icon" className="img-fluid" />
            </button>
            <Button type="button" className="btn" style={buttonStyle} onClick={handleSubmit}>Add Note</Button>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* <!-- Note Area Ends Here --> */}
  </SecondHeaderComponent>
        </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(NewNoteComponent);
