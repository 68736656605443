import React from 'react'
import UserComponent from '../components/UserComponent/UserComponent'

const UserPage = () => {
    return (
        <div>
             <UserComponent />
        </div>
    )
}

export default UserPage
