import React  , { useState , useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { api_url } from '../../utils/api';
import { getUserTags } from '../../redux/actions/authActions';
import axios from 'axios';
import moment from 'moment'
import 'moment-timezone'

import {Button, Checkbox, Segment, Loader, Dimmer, Grid, TransitionablePortal} from 'semantic-ui-react';

import { connect , useDispatch } from 'react-redux';
import SecondHeaderComponent from '../common/header/header2'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './style.css';

const token = localStorage.getItem("jwtToken");
const configure = {
  headers: { Authorization: `Bearer ${token}` }
};


const ReminderComponent = (props) => {

  const userName = props.auth.userData.username;

  const [Data, setData] = useState([])
  const [Reminders, setReminders] = useState([])
  let [pageNumber, setPageNumber] = useState(1)
  let [showLoadMore, setShowLoadMore] = useState(false)

  const token = props.auth.userData.token;


 const colorWhite = {
   color: 'white',
   backgroundColor: '#93D9F8'
 }


 const JWTtoken = localStorage.getItem("jwtToken");
 var config = {
  method: 'get',
  url: `${api_url}/api/tags`,
  headers: { 
    'Authorization': `Bearer ${JWTtoken}`
  },
};


let history = useHistory()


//get all the tags data
 const getTagsData = () => {
  axios(config)
  .then(function (response) {
setData(response.data.payload)
  })
  .catch(function (error) {
  });
  
 }

 const handleLoadMore = () => {

  let page = {
    page: pageNumber,
    isarchive: 0
  }

  axios.post(api_url+'/api/notes-list',page,configure)
  .then((response)=>{
    if(response.length > 0){
      setReminders((values) => ([ 
        ...values.concat(response.data.payload.data.filter((notes)=> notes.n_reminder != null))
      ]))
      setPageNumber(pageNumber + 1)
      if(response.length > 8){
        setShowLoadMore(true)
      }
    }
    else if(response.length <= 0){
      toast.warn("No Data Available !!!")
      setShowLoadMore(false)
    }
  })
  .catch((error)=>{
    console.log("error :: ",error)
  })
 }

  useEffect(() => {

    if(token == 'undefined'){
      console.log("inside first token check !!!")
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
    }
     else if(token == null){
      console.log("inside second token check !!!")
  
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
     } else if(token == undefined){
      console.log("inside third token check !!!")
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
    }
    else if(token == ''){
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname:'/login'})
    }

  let page = {
    page: 0,
    isarchive: 0
  }

  axios.post(api_url+'/api/notes-list',page,configure)
  .then((response)=>{
    if(response){
      setReminders(response.data.payload.data.filter((notes)=> notes.n_reminder != null))
      // setPageNumber(pageNumber + 1)
    }
  })
  .catch((error)=>{
    console.log("error :: ",error)
  })

  // let guessZone = moment.tz.guess();
  // let customDate = '2021-12-12T12:00'
  // let test = moment(customDate).tz(guessZone).format('ha z')
  // console.log("guess time zone :: ",test)
}, [])
// console.log("reminders :: ",Reminders)




    return (
      <div>
        {/* <!-- Header Starts Here --> */}
        <SecondHeaderComponent hideClock={true} >

        <section className="note-area manage-tags mt-0" style={{
                backgroundImage: `url(assets/images/background/02.jpg)`,
              }}>
          <div className="container">
            <div
              className="row user-profile justify-content-center"
            >
              <div className="manage-tags-area">
                <h4>Notes with Reminders</h4>
              </div>

              <div className="col-lg-12">
                <div className="row justify-content-between">
                  
                  {Reminders.length > 0 ? Reminders.map((notes, indx) => {
                    let testsplit = JSON.parse(notes.n_reminder)
                    let cycle = testsplit[0].cycle
                    let date = testsplit[0].date
                    let time = testsplit[0].time
                    let dateandtime = date+ 'T' +time
                      return (
                        <div className="col-lg-6 mb-md-5 mb-lg-0">
                        <div key={indx} className="tags-wizerd">
                          <p style={{color:'white',fontSize:'12px'}}>{moment(dateandtime).format('MMMM Do YYYY, h:mm a')}</p>
                          
                          <div className="tags-wizerd-left">
                            <h6 style={{fontSize:'30px'}}>{notes.n_title}</h6>
                            <button
                          className="ui inverted primary button icon btn"
                            // className="btn"
                            style={{marginTop:'-33px'}}
                            type="button"
                            onClick={()=>{ history.push({
                              pathname:`edit/${notes.n_uuid}`,
                              state:{
                                NoteData:notes
                              }})
                            }}
                          >
                            <i className="eye icon"></i>
                          </button>
                          </div>
                        </div>
                        </div>
                      );
                    }) : <h2>No Notes Available !!</h2>}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="manage-tags-bottom">
                  <div className="text-center text-md-center text-lg-center" style={{paddingBottom: '30px'}}>
                  {showLoadMore ? <Button inverted color="secondary" onClick={()=> handleLoadMore()} style={{borderRadius:'20px'}}>Load More</Button> : null}
                  </div>
                  <div className="text-center text-md-end text-lg-end">
                    <Link style={colorWhite} to="/newNote" className="btn accent-btn-sm">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24 12C24 13.106 23.904 14 22.798 14H14V22.798C14 23.902 13.106 24 12 24C10.894 24 10 23.902 10 22.798V14H1.202C0.0979997 14 0 13.106 0 12C0 10.894 0.0979997 10 1.202 10H10V1.202C10 0.0959997 10.894 0 12 0C13.106 0 14 0.0959997 14 1.202V10H22.798C23.904 10 24 10.894 24 12Z"
                          fill="#1B2330"
                        />
                      </svg>
                      Add new
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </SecondHeaderComponent>
      </div>
    );
}

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(ReminderComponent);