import React from 'react'
import NewNoteComponent from '../components/NewNote/NewNoteComponent'

const NewNotePage = () => {
    return (
        <div>
            <NewNoteComponent />
        </div>
    )
}

export default NewNotePage
