import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { userLogout, getNotes, deleteNote, getUserTags, getTagNotes, acrhiveNote } from '../../redux/actions/authActions';
import Header from '../common/header/HeaderComponent';
import moment from "moment";
import qs from 'qs'
import './styles.css';
import {Button, Checkbox, Segment, Loader, Dimmer, Grid, TransitionablePortal, Menu, Sidebar, Image, Reveal, Popup, Confirm} from 'semantic-ui-react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import toast from '../../assets/toastCustom/toast'
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import es from 'date-fns/esm/locale/es/index.js';

const api_url = "https://dev7.invitocreatives.com";
const token = localStorage.getItem("jwtToken");
const config = {
  headers: { Authorization: `Bearer ${token}` }
};
const HomePageComponent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const checkStoreValue = state => state.auth.result;
  const storeStateResult = useSelector(checkStoreValue, shallowEqual);

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    border: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const modalCenter = {
    left: 'unset',
    marginTop: '180px',
    height: '22%'
  }
console.log("props :: ",props)

// --------------  States ----------------- 


 const [getUsrNotes, setUsrNotes] = useState([]);
 const [userTags, setUserTags] = useState([]);
 const [pageNumber, setPageNumber] = useState(1);
 const [sort, setSort] = useState()
 const [Loading, setLoading] = useState(true);
 const [Reminders, setReminders] = useState([])
 const [IsOpen, setIsOpen] = useState(false)
 const [sideBarShow, setSideBarShow] = useState(false)
 let [ShowLoadmore, setShowLoadmore] = useState(false)
 let [CheckSort, setCheckSort] = useState("DESC")
 let [Open, setOpen] = useState(false)
 let [ArchiveOpen, setArchiveOpen] = useState(false)
 let [DeleteNoteValue, setDeleteNoteValue] = useState('')
 let [ArchiveNoteValue, setArchiveNoteValue] = useState('')
 let [ShowLogout, setShowLogout] = useState(false)


//  ----------------------- Handlers ------------------------

 const logoutHandler = () => {
   dispatch(userLogout());
   console.log("Inside logout hameComponent ...")
   history.push({pathname:'/login'})
  }

  let ShowLogoutToggle = () => {
    console.log("value of ShowLogout",ShowLogout)
    setShowLogout(true);
  }
  let LogoutConfirm = <Confirm
  content='Do You want to Logout ?'
   style={modalCenter}
    open={ShowLogout}
     onCancel={() => setShowLogout(false)}
     onConfirm={logoutHandler} 
     />

 const userName = props.auth.userData.username

//  console.log("props :: ",props)

 const handleOpen = () => {
  setIsOpen(true)
}
 const handleClose = () => {
  setIsOpen(false)
}


const handleTagsFilter = async(e) => {
  setLoading(true)
  let tag_id = e.target.value;
  // console.log('Before',props.auth.result);

  let data = { tags: '['+tag_id+']',
      isarchive: '0' }

  // console.log("tag id LL = :: ",data)

  let test = {
    'tags' : [tag_id]
  }
  let tags = JSON.stringify(test);
  // console.log("test here :: ",test)
  
  await axios.post(api_url+'/api/notes-list',data,config)
  .then((response) => {
    // console.log("vlaue of tags :: ",tags)
    // console.log("api response from getNotes action :: ",response.data.payload.data)
    setUsrNotes(response.data.payload.data);
    setLoading(false)
  })
  .catch((error) => {
    setUsrNotes([]);
    setLoading(false)
  })
}

const handleAllFilter = async() => {
  setLoading(true)
  // console.log('ALL')

 await axios.post(api_url+'/api/notes-list',{isarchive : '0'},config)
.then((response) => {
  // console.log("api response from gesdfasdfasdftNotes action :: ",response)
  setUsrNotes(response.data.payload.data.filter((notes) => notes.n_isarchive == "0"))
  setLoading(false);

  // console.log("user notes :: ",getUsrNotes)
})
.catch((error) => {
  setUsrNotes([])
  setLoading(false);
})

}

const handleSort = param => (e) => {
  setLoading(true)
  let sortValue = ''
  if(param.type === 'createdAt'){
    sortValue = `{"type": "createdAt", "order": ${JSON.stringify(CheckSort)}}`
  }
  else if(param.type === 'updatedAt'){
    sortValue = `{"type": "updatedAt", "order": ${JSON.stringify(CheckSort)}}`
  }
  else{
    sortValue = `{"type": "createdAt", "order": ${JSON.stringify(CheckSort)}}`
  }

  let sort = {
    sort: sortValue,
    isarchive: '0'
  }


  // console.log("param : : ",param.type)
  let test = JSON.stringify(sort)
  // console.log("value of sort :: ",test)
  setSort(sort)
  axios.post(api_url+'/api/notes-list',sort,config)
  .then((response)=>{
    if(CheckSort === "ASC"){
      setCheckSort("DESC")
    }
    else{
      setCheckSort("ASC")
    }
    console.log("response from api : :  ",response)
    setUsrNotes(response.data.payload.data.filter((notes) => notes.n_isarchive == "0"))
    let testReminder = response.data.payload.data.filter((notes) => notes.n_reminder != null)
    // console.log("result of testReminder : : ",testReminder);
    setReminders(response.data.payload.data.filter((notes) => notes.n_reminder != null))
    setLoading(false);
  })
  .catch((error)=>{
    console.log("Error occured :: ",error);
  })
}

const handleShowReminder = () => {
  history.push("/reminderNotes")
}


const handleLoadMore = async(e) => {
  setLoading(true)
  // console.log("Page number in front-end :: ",pageNumber)
  let page = {
    page: JSON.stringify(pageNumber),
    isarchive: '0'
  }

  await axios.post(api_url+'/api/notes-list',page,config)
  .then((response) => {
    // console.log("api response from gesdfasdfasdftNotes action :: ",response)
    if(response.data.payload.data.length > 0) {
      setUsrNotes( (values) => ([
        ...values.concat(response.data.payload.data.filter((notes) => notes.n_isarchive == "0"))
      ]))
      setPageNumber(pageNumber + 1)
    setLoading(false);
    }
    else if(response.data.payload.data.length == 0){
      toast.success("No Notes Found !!!")
      setUsrNotes( (values) => ([
        ...values
      ]))
      setShowLoadmore(false)
      setLoading(false);
    }  
    // console.log("user notes :: ",getUsrNotes)
  })
  .catch((error) => {
    setUsrNotes([])
    setLoading(false);
  })
}


const delNote = param => e => {
  const ids = {
    'ids': [param]
  }
  setDeleteNoteValue('')
  setOpen(false)
  dispatch(deleteNote(ids))
  toast.success("Note Deleted !!");
  if(storeStateResult.filter((notes)=> notes.n_uuid === param)){
    setUsrNotes(getUsrNotes.filter((notes) => notes.n_uuid !== param))
  }
}
let deleteConfirmShow = param => e => {
  setDeleteNoteValue(param)
  console.log("value of param :: ",param);
  setOpen(true);
}
// console.log("value of DeleteNoteValue :: ",DeleteNoteValue)
let deleteConfirm = <Confirm
content='Do You want to Delete this Note ?'
 style={modalCenter}
  open={Open}
   onCancel={() => setOpen(false)}
   onConfirm={DeleteNoteValue != undefined ? delNote(DeleteNoteValue) : toast.error("Unable to fetch Tag ID !!!")}
    />



const ArchiveNote = param => e => {
  setArchiveOpen(false)
  const ids = {
    'ids': [param]
  }

  const from = {
    component: 'HOME'
  }

  dispatch(acrhiveNote(ids,from))
  toast.success("Note Archived !!");
  // console.log("note archived !!",param);
  if(getUsrNotes.filter((notes)=> notes.n_uuid === param)){
    setUsrNotes(getUsrNotes.filter((notes) => notes.n_uuid !== param))
  }
}

let ArchiveConfirmShow = param => e => {
  setArchiveNoteValue(param)
  setArchiveOpen(true);
}
let ArchiveConfirm = <Confirm
content='Do You want to Archive this Note ?'
 style={modalCenter}
  open={ArchiveOpen}
   onCancel={() => setArchiveOpen(false)}
   onConfirm={ArchiveNoteValue != undefined ?  ArchiveNote(ArchiveNoteValue) : toast.error("Unable to fetch Note ID")} 
   />

//   ------------------- Use Effect (Life cy)------------

 const colorWhite = {
   color: 'white',
   padding: '15px 35px'
 }

 const bluecolor = {
  color : '#33435B',
  paddingLeft: '10px',
  fontWeight: '1000',
  fontSize: '16px'
}

let cancelToken
const handleSearch = async(e) => {

  // let title = {
  //   title: e.target.value,
  //   isarchive: 0
  // }

 let data = qs.stringify({
    title: e.target.value 
  })

  let configure = {
    method: 'post',
    url: api_url+'/api/notes-list',
    headers: { Authorization: `Bearer ${token}` },
    data:data
  }

    const searchTerm = e.target.value

    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.")
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()

    try {
      const results = await axios(configure,
        { cancelToken: cancelToken.token } //Pass the cancel token to the current request
      )
      setUsrNotes(results.data.payload.data.filter((notes) => notes.n_isarchive == "0"))
      console.log("Results 1232132 for " + searchTerm + ": ", results.data.payload.data)
    } catch (error) {
      toast.error("Something Went Wrong !!!!");
      console.log(error)
    }
  
}

let friendOptions = userTags.length > 0 && userTags.map((tag,index)=>{
  return {
    key: tag.tag_id,
    text: tag.title,
    value: tag.tag_id
  }
})


useEffect(()=>{

  // console.log(props,props.result, "here are Props")

  console.log("this will be called when the component will be mounted ....")
  if(token == 'undefined'){
    // console.log("inside first token check !!!")
    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname: '/login'})
  }
   else if(token == null){
    // console.log("inside second token check !!!")

    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname: '/login'})
   } else if(token == undefined){
    // console.log("inside third token check !!!")
    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname: '/login'})
  }
  else if(token == ''){
    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname:'/login'})
  }

  dispatch(getNotes())
  dispatch(getUserTags())
  
axios.post(api_url+'/api/notes-list',{isarchive : '0'},config)
.then((response) => {
  // console.log("api response from gesdfasdfasdftNotes action :: ",response)
  if(response.data.payload.data.length > 0){
    setUsrNotes(response.data.payload.data.filter((notes) => notes.n_isarchive == "0"))
    let testReminder = response.data.payload.data.filter((notes) => notes.n_reminder != null)
    if(response.data.payload.data.length > 9){
      setShowLoadmore(true)
    }
    // console.log("result of testReminder : : ",testReminder);
    setReminders(response.data.payload.data.filter((notes) => notes.n_reminder != null))
    setLoading(false);
  }

    // console.log("value of reminder state :: ",Reminders);

  // console.log("user notes :: ",getUsrNotes)
})
.catch((error) => {
  setUsrNotes([])
})
// if(storeStateResult.length > 0) {
//   setUsrNotes(storeStateResult)
// }

axios.get(api_url+'/api/tags',config)
.then((response) => {
  // console.log("api response from tagsss action :: ",response)
  setUserTags(response.data.payload)
  setLoading(false);
})
.catch((error) => {
  // console.log("error :: ",error);
  setUserTags([])
})

},[])

function myFunction() {
	document.getElementById("myDropdown").classList.toggle("show");
}

  const sideBarCloseHandler =() => {
    	document.getElementById("mySidebar").style.display = "none";
      document.getElementById("main-header").style.display = "block";
      document.getElementById("home-screen").style.display = "block";
  }

  const sideBarOpenHandler = () => {
    	document.getElementById("mySidebar").style.display = "block";
      document.getElementById("main-header").style.display = "none";
      document.getElementById("home-screen").style.display = "none";
  }
 
    return (
      <div>
        <div>
        <header className="main-header" id="main-header">
          <div className="container-fluid">
            <nav>
              <div className="main-header-start">
                <div className="hamburger">
                  <button
                    className="btn hamburger-btn"
                     onClick={()=> setSideBarShow(true)}
                  >
                    <img
                      src="assets/images/icon/mainhamburger.png"
                      alt="Icon"
                      className="img-fluid"
                    />
                  </button>
                </div>
                <img
                  src="assets/images/logo/small.png"
                  alt="Logo"
                  className="img-fluid"
                />
              </div>
              <div className="main-header-end">
                <div className="search-field search-box">
                  <form action="#">
                    <input
                      type="search"
                      placeholder="Search"
                      className="form-control"
                      onKeyDown={handleSearch}
                    />
                    <img
                      src="assets/images/icon/search.svg"
                      alt="icon"
                      className="img-fluid"
                    />
                  </form>
                </div>
                <div className="notification">
                  <button type="button" onClick={()=> handleOpen()} className="btn">
                    <img
                      src="assets/images/icon/clock.svg"
                      alt="Clock"
                      className="img-fluid"
                    />
                  </button>
                </div>
                <Link
                  className="nav-link avatar-area d-flex align-items-center justify-content-center"
                  to="/user"
                >
                  <div className="avatar">
                    <img
                      src="assets/images/icon/user.svg"
                      alt="User"
                      className="img-fluid"
                    />
                  </div>
                  <span>{userName}</span>
                </Link>
              </div>
            </nav>
          </div>
        </header>

          <main>
          <Grid style={{marginTop: '-40px'}} className="ParentContainer" columns={2}>
        <Grid.Column>
          <TransitionablePortal
            open={IsOpen}
            onClose={handleClose}
          >
            <Segment className="reminderList">
              {Reminders.length > 0 ? <>
              {Reminders.map((notes,index)=>{ 
                // console.log(index,notes.n_reminder,notes.n_title,"  reminders in home component")
                let testsplit = {}
                try {
                   testsplit = JSON.parse(notes.n_reminder)
                    let cycle = testsplit[0].cycle
                let date = testsplit[0].date
                let time = testsplit[0].time
                let dateandtime = date+ 'T' +time
                if(index <= 2){
                return (
                <div style={{width:'250px',borderBottom: '0.2px solid #00000033',marginBottom: '2px', padding: '5px 0px'}}>
                <p style={{fontSize:'12px'}}>{moment(dateandtime).format('MMMM Do YYYY, h:mm a')}</p>
                <p style={{marginTop:'-10px',fontSize:'22px'}}><b>{notes.n_title}</b></p>
                </div>
              )
                }
                } catch (error) {
                  toast.error('Data Not parsed');
                }
             })}
                </> : <p>No Reminders</p>}
                <Button inverted color="blue" onClick={()=> handleShowReminder()} className="input-group">Show more</Button>
            </Segment>
          </TransitionablePortal>
        </Grid.Column>
      </Grid>
        
      <Grid className="" columns={1}>
        <Grid.Column>
      <Sidebar.Pushable as={Segment} style={{ overflow: 'hidden' }}>
      <Sidebar
              className="fixed-top"
              as={Menu}
              animation='overlay'
              icon='labeled'
              inverted
              onHide={() => setSideBarShow(false)}
              vertical
              visible={sideBarShow}
              width='thin'
              style={{backgroundColor:'#D9D9D9',color:'#33435B',width:'320px',textAlign:'unset',padding: '15px 30px'}}
            >
              <Menu.Item style={{textAlign:'unset'}}>
        <Link to="/tags">
              <img
                src="assets/images/icon/01.png"
                alt="Icon"
                className="img-fluid"
              />{" "}
              <span style={bluecolor}>Manage tags</span>
            </Link>
        </Menu.Item>
      <br/>
        <Menu.Item style={{textAlign:'unset'}}>
            <Link to='/archives'>
            <img
                src="assets/images/icon/02.png"
                alt="Icon"
                className="img-fluid"
              />{" "}
              <span style={bluecolor}>Archives</span>
            </Link>
        </Menu.Item>
      <br/>
      <Menu.Item  style={{textAlign:'unset'}}>
      <Link to="/user">
              <img
                src="assets/images/icon/03.png"
                alt="Icon"
                className="img-fluid"
              />{" "}
              <span style={bluecolor}>Manage Profile</span>
            </Link>
      </Menu.Item>
      <br/>
      <Menu.Item style={{textAlign:'unset'}}>
      <Link to="/resetPassword">
              <img
                src="assets/images/icon/04.png"
                alt="Icon"
                className="img-fluid"
              />{" "}
              <span style={bluecolor}>Change password</span>
            </Link>
      </Menu.Item>
      <br/>
      <Menu.Item style={{textAlign:'unset'}}>
      <Link to="/subscription">
              <img
                src="assets/images/icon/05.png"
                alt="Icon"
                className="img-fluid"
              />{" "}
              <span style={bluecolor}>Subscription</span>
            </Link>
      </Menu.Item>
      <br/>
      <Menu.Item style={{textAlign:'unset'}}>
      <>
            <a onClick={()=>ShowLogoutToggle()}>
              <img
                src="assets/images/icon/06.png"
                alt="Icon"
                className="img-fluid"
              />
              <span style={bluecolor} >Logout</span>
            </a>
     </>
     </Menu.Item>
            </Sidebar>
        <Sidebar.Pusher >
          <Segment basic>
        <section className="note-area home-screen mt-0" id="home-screen">
        <div className="col-lg-12">
          
                <div className="home-screen-top">
                <div>
                <Button onClick={handleAllFilter} style={{borderRadius:'20px',marginRight:'10px',marginBottom:'10px'}} inverted color="secondary">All</Button>
                            
                  {userTags.length < 5 ? (userTags.map((tags,index)=>{
                    console.log("values of tags filter :: ",tags);
                    return(                               
                      <Button onClick={handleTagsFilter} key={index} value={tags.tag_id} style={{borderRadius:'20px',marginRight:'10px',marginBottom:'10px'}} inverted color="blue">{tags.title}</Button>
                    )
                  })) : <> <Popup wide trigger={<Button inverted color="blue" style={{borderRadius:'20px',marginRight:'10px'}} content='TAGS' />} position='bottom left' on='click'>
                    <Grid Columns={2} divided>
                      {userTags.map((tags,index) => {
                       return( <Button onClick={handleTagsFilter} key={index} value={tags.tag_id} style={{borderRadius:'20px',margin:'5px'}} inverted color="blue">{tags.title}</Button>
                        )
                      })}
                      </Grid>
                    </Popup> </>}
                </div>

                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn filter-bnt dropbtn"
                      onClick={myFunction}
                      style={{
                        backgroundImage: `url(assets/images/icon/filter.svg)`,
                        width:'50px',
                        height:'50px',
                        padding: '0px'
                      }}
                    ></button>
                    <div id="myDropdown" className="dropdown-content">
                      <a style={{color:'white'}} onClick={handleSort({type:'createdAt'})}>
                        <img
                          src="assets/images/icon/updown.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                        Date created
                      </a>
                      <a style={{color:'white'}} onClick={handleSort({type:'updatedAt'})}>
                        <img
                          src="assets/images/icon/updown.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                        Date modified
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            
          <div className="container">
          {getUsrNotes.length > 0 ? (Loading ? <> <Dimmer active inverted>
                <Loader size='large'>Loading</Loader>
                </Dimmer>
            <div
              className="row user-profile justify-content-center"
              style={{
                backgroundImage: `url(assets/images/background/02.jpg)`,
              }}
            >
              
              <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
              {getUsrNotes.length > 0 && getUsrNotes.map((value) =>{
                // console.log("N description :: ",value.n_description.length)
                  return(
                    <li style={{listStyle:'none'}} key={value.id} >
                      <div className="ui card" 
                               style={{borderRadius: '25px',padding: '10px 0px 0px 0px',width:'unset'}}>
                        <div className="content" onClick={() =>{ history.push({
                               pathname:`edit/${value.n_uuid}`,
                               state:{
                                 NoteData:value
                               }
                              }) }}>
                          <div className="header"><h4>{value.n_title}</h4></div>
                          <div className="description" style={{padding:'10px 0px'}}>
                            {value.n_description ? value.n_description.length > 65 ? <div dangerouslySetInnerHTML={{ __html: value.n_description.replace(/(<([^>]+)>)/ig, '').substr(0,150)+' ...' }} /> : <div dangerouslySetInnerHTML={{ __html: value.n_description }} /> : <div dangerouslySetInnerHTML={{ __html: value.n_description }} />}
                          <div className="ui cards" style={{paddingTop:'10px',justifyContent:'center'}}>
                          {value.notes_images.length > 1 ? (value.notes_images.map((image, init)=>{
                              return(
                                <Reveal key={init} animated='move' >
                                  {init == 0 && <> <Reveal.Content visible>
                                  <Image size='small' loading="lazy" src={image.ni_url} alt="user_image" style={{border:'1px solid #2C394E',marginTop:'8px'}} />
                                </Reveal.Content>
                                <Reveal.Content hidden>
                                  <h5 style={{padding:'45px 0px',display:'flex',justifyContent:'center'}} >{value.notes_images.length - 1} More</h5>
                                </Reveal.Content> </> }
                                </Reveal>
                                
                              )
                            })) : (value.notes_images.map((image, init)=>{
                              return(
                                <div key={init}>
                                <Reveal.Content visible>
                                  <Image size='small' loading="lazy" src={image.ni_url} alt="user_image" style={{border:'1px solid #2C394E',marginTop:'8px'}} />
                                </Reveal.Content>
                                <Reveal.Content hidden>
                                  {/* <h5>{value.notes_images.length} More</h5> */}
                                </Reveal.Content> 
                                  </div>
                              )
                            })) }
                          </div>
                          <div style={{marginTop:'18px'}}>
                            {value.notes_tags.map((tags,indexes)=>{
                              return(
                                <button key={indexes} className="ui inverted button"
                                 style={{marginTop:'2px',borderRadius:'18px',backgroundColor:'#93D9F8'}}
                                >{tags.tag.tg_title}</button>
                              )
                            })}
                            </div>
                            <div style={{overflow:'hidden',marginTop:'18px'}}>
                              {value.notes_tasks.map((task,Index)=>{
                                // let parsedTasks = JSON.parse(task);
                                console.log("value of parsed tasks :: ",task.nta_ischecked);
                                return (<>
                                  <Checkbox key={Index} checked={(task.nta_ischecked == '0' ? false : true)} label={task.nta_title} disabled/>
                                  <br/>
                                </>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="extra content">
                        <div className="left floated author">
                          <div>
                          <button className="ui inverted secondary button icon"
                             onClick={()=>{ history.push({
                               pathname:`edit/${value.n_uuid}`,
                               state:{
                                 NoteData:value
                               }
                              }) }}
                              value={value.n_uuid}
                              style={{borderRadius:'46%'}}
                              >
                                <i className="pencil alternate icon"></i>
                                </button>
                                <button className="ui inverted secondary button icon"
                                onClick={deleteConfirmShow(value.n_uuid)}
                                style={{borderRadius:'46%'}}
                                >
                                  <i class="trash icon"></i>
                                </button>
                                <button className="ui inverted secondary button icon"
                              onClick={ArchiveConfirmShow(value.n_uuid)}
                              style={{borderRadius:'46%'}}
                              >
                                <i className="archive icon"></i>
                                </button>
                          </div>
                          </div>
                          <div className="right floated author" style={{fontSize:'12px'}}>
                          {moment(value.updatedAt).format('MMMM DD YYYY')}
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
                </Masonry>

               
              <div className="col-lg-12" style={{paddingTop:'20px'}}>
                <div className="row">
                    <div className="d-flex justify-content-center justify-content-lg-center">
                    {ShowLoadmore ? <Button onClick={handleLoadMore} style={{borderRadius:'20px'}} inverted color="secondary">Load More</Button> : null }
                    </div>
                </div>
                <br/>
                <div className="row">
                <div className="d-flex justify-content-center justify-content-lg-end">
                           <Link to="/newNote" className="btn accent-btn-sm" style={colorWhite} >
                             <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M24 12C24 13.106 23.904 14 22.798 14H14V22.798C14 23.902 13.106 24 12 24C10.894 24 10 23.902 10 22.798V14H1.202C0.0979997 14 0 13.106 0 12C0 10.894 0.0979997 10 1.202 10H10V1.202C10 0.0959997 10.894 0 12 0C13.106 0 14 0.0959997 14 1.202V10H22.798C23.904 10 24 10.894 24 12Z"
                                fill="#1B2330"
                              ></path>
                            </svg>
                            Create
                          </Link>
                        </div>
                </div>
              </div>
            </div> </> : <>
            <div
              className="row user-profile justify-content-center"
              style={{
                backgroundImage: `url(assets/images/background/02.jpg)`,
              }}
            >

              <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
              {getUsrNotes.length > 0 && getUsrNotes.map((value) =>{
                  return(
                    <li style={{listStyle:'none'}} key={value.id} >
                      <div className="ui card" 
                               style={{borderRadius: '25px',padding: '10px 0px 0px 0px',width:'unset'}}>
                        <div className="content" onClick={() =>{ history.push({
                               pathname:`edit/${value.n_uuid}`,
                               state:{
                                 NoteData:value
                               }
                              }) }}>
                          <div className="header"><h4>{value.n_title}</h4></div>
                          <div className="description" style={{padding:'10px 0px'}}>
                          {value.n_description ? value.n_description.length > 145 ? <div dangerouslySetInnerHTML={{ __html: value.n_description.replace(/(<([^>]+)>)/ig, '').substr(0,150)+' ...' }} /> : <div dangerouslySetInnerHTML={{ __html: value.n_description }} /> : <div dangerouslySetInnerHTML={{ __html: value.n_description }} />}
                          <div className="ui cards" style={{paddingTop:'10px',justifyContent:'center'}}>
                            
                            {value.notes_images.length > 1 ? (value.notes_images.map((image, init)=>{
                              return(
                                <Reveal key={init} animated='move' >
                                  {init == 0 && <> <Reveal.Content visible>
                                  <Image size='small' loading="lazy" src={image.ni_url} alt="user_image" style={{border:'1px solid #2C394E',marginTop:'8px'}} />
                                </Reveal.Content>
                                <Reveal.Content hidden>
                                  <h5 style={{padding:'45px 0px',display:'flex',justifyContent:'center'}} >{value.notes_images.length - 1} More</h5>
                                </Reveal.Content> </> }
                                </Reveal>
                                
                              )
                            })) : (value.notes_images.map((image, init)=>{
                              return(
                                <div key={init}>
                                <Reveal.Content visible>
                                  <Image size='small' loading="lazy" src={image.ni_url} alt="user_image" style={{border:'1px solid #2C394E',marginTop:'8px'}} />
                                </Reveal.Content>
                                <Reveal.Content hidden>
                                  {/* <h5>{value.notes_images.length} More</h5> */}
                                </Reveal.Content> 
                                  </div>
                              )
                            })) }
                            {/* {value.notes_images.map((image, init)=>{
                              return(
                                <div key={init}>
                                  
                                    <Image size='small' loading="lazy" src={image.ni_url} alt="user_image" style={{border:'1px solid #2C394E',marginTop:'8px'}} />
                                    
                                  </div>
                              )
                            })} */}
                          </div>
                          <div style={{marginTop:'18px'}}>
                            {value.notes_tags.map((tags,indexes)=>{
                              return(
                                <button key={indexes} className="ui inverted button"
                                 style={{marginTop:'2px',borderRadius:'18px',backgroundColor:'#93D9F8'}}
                                >{tags.tag.tg_title}</button>
                              )
                            })}
                            </div>
                            <div style={{marginTop:'18px',overflow:'hidden'}}>
                              {value.notes_tasks.map((task,Index)=>{
                                return (<>
                                    <Checkbox key={Index} checked={(task.nta_ischecked == '0' ? false : true)} label={task.nta_title} disabled/>
                                  <br/>
                                </>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="extra content">
                        <div className="left floated author">
                          <div>
                          <button className="ui inverted secondary button icon"
                             onClick={()=>{ history.push({
                               pathname:`edit/${value.n_uuid}`,
                               state:{
                                 NoteData:value
                               }
                              }) }}
                              value={value.n_uuid}
                              style={{borderRadius:'46%'}}
                              >
                                <i className="pencil alternate icon"></i>
                                </button>
                                <button className="ui inverted secondary button icon"
                                onClick={deleteConfirmShow(value.n_uuid)}
                                style={{borderRadius:'46%'}}
                                >
                                  <i class="trash icon"></i>
                                </button>
                                <button className="ui inverted secondary button icon"
                              onClick={ArchiveConfirmShow(value.n_uuid)}
                              style={{borderRadius:'46%'}}
                              >
                                <i className="archive icon"></i>
                                </button>
                          </div>
                          </div>
                          <div className="right floated author" style={{fontSize:'12px'}}>
                          {moment(value.updatedAt).format('MMMM DD YYYY')}
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
                </Masonry>
                
              <div className="col-lg-12" style={{paddingTop:'20px'}}>
                <div className="row">
                    <div className="d-flex justify-content-center justify-content-lg-center">
                    {ShowLoadmore ? <Button onClick={handleLoadMore} style={{borderRadius:'20px'}} inverted color="secondary">Load More</Button> : null }
                    </div>
                </div>
                <br/>
                <div className="row">
                <div className="d-flex justify-content-center justify-content-lg-end">
                           <Link to="/newNote" className="btn accent-btn-sm" style={colorWhite} >
                             <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M24 12C24 13.106 23.904 14 22.798 14H14V22.798C14 23.902 13.106 24 12 24C10.894 24 10 23.902 10 22.798V14H1.202C0.0979997 14 0 13.106 0 12C0 10.894 0.0979997 10 1.202 10H10V1.202C10 0.0959997 10.894 0 12 0C13.106 0 14 0.0959997 14 1.202V10H22.798C23.904 10 24 10.894 24 12Z"
                                fill="#1B2330"
                              ></path>
                            </svg>
                            Create
                          </Link>
                        </div>
                </div>
              </div>
            </div> </>) : <> <h2>Add Notes !!</h2> <div className="col-lg-12" style={{paddingTop:'20px'}}>
                <div className="row">
                    <div className="d-flex justify-content-center justify-content-lg-center">
                    {ShowLoadmore ? <Button onClick={handleLoadMore} style={{borderRadius:'20px'}} inverted color="secondary">Load More</Button> : null }
                    </div>
                </div>
                <br/>
                <div className="row">
                <div className="d-flex justify-content-center justify-content-lg-end">
                           <Link to="/newNote" className="btn accent-btn-sm" style={colorWhite} >
                             <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M24 12C24 13.106 23.904 14 22.798 14H14V22.798C14 23.902 13.106 24 12 24C10.894 24 10 23.902 10 22.798V14H1.202C0.0979997 14 0 13.106 0 12C0 10.894 0.0979997 10 1.202 10H10V1.202C10 0.0959997 10.894 0 12 0C13.106 0 14 0.0959997 14 1.202V10H22.798C23.904 10 24 10.894 24 12Z"
                                fill="#1B2330"
                              ></path>
                            </svg>
                            Create
                          </Link>
                        </div>
                </div>
              </div> </> }
         

          </div>
        </section>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>   
      </Grid.Column>
      </Grid>
        {/* <!-- Home Screen Area Ends Here --> */}
        </main>
        </div>
        {deleteConfirm}
        {ArchiveConfirm}
        {LogoutConfirm}
      </div>

    );
}

const mapStateToProps = (state) => {
  // console.log(state, "State in HomePgaeComponent ------------------------------->")
  return ({ auth: state.auth, result: state.auth.result || [] })};

export default connect(mapStateToProps)(HomePageComponent)