import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { RedirectLogin } from '../../utils/common.js';

import axios from "axios";
import qs from "qs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useForm, Controller } from "react-hook-form";

import { Route, Redirect } from "react-router-dom";

import { connect , useDispatch} from "react-redux";
import { api_url } from "../../utils/api";

import { updateProfile, userLogout } from "../../redux/actions/authActions";

const DetailComponent = (props) => {
     const history = useHistory()
     const dispatch = useDispatch()
     const isAuthenticated = props.auth.isAuthenticated;
     console.log(" prop :: ", props);
    const userEmail = localStorage.getItem('email') ;
    const token = localStorage.getItem('jwtToken')
    const userNumber = props.auth?.userData?.mobile ;
    const userName = props.auth?.userData?.username ;
  
    const { register, handleSubmit, control } = useForm({
      defaultValues: {
        "email" : userEmail,
        "mobile" : userNumber,
        "username" : userName
      },
      // console.log("value of control in user details :: ",control)
    });

    const redirectHandler = () => {
      setTimeout(() => {
        history.push("/login")
        }, 5000);
    }
  const submitHandler = (data) => {
    console.log(data);
    console.log("Username :: ",data.username);
    console.log("username length :: ",data.username.length)

    console.log('email',userEmail);
    console.log('token',token);

    const password = data.password;
    const newPassword =  data.cpassword;
    if(password != newPassword){
          //console.log("Password doesn't match");
      toast.warn("Password doesn't match ");
      return;
    }

   if(data.checked !== true){
    toast.warn("please check terms & conditions ");
      return;
   }   

   
   let finalData = qs.stringify({
      username: data.username,
      mobile: data.mobile,
      email: userEmail,
      password: data.password
    });
    console.log(finalData); 
    let config = {
      method: "post",
      url: `${api_url}/api/update-profile`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: finalData,
    };

    //  //console.log("user =>" , user)
    ////console.log("id", signupId)

    if(data.username.length < 4){
      toast.error("Username should be greater than 4 characters !!")
    }
    if(data.username.length > 30){
      toast.error("Username can not be greater than 30 characters !!")
    }
    if(data.password.password < 8){
      toast.error("Password length should be greater than 8 characters !!")
    }

    else{
      axios(config)
      .then((res) => {
        if(res.data.status == true){
          toast.success(`You have Signup successfully | Now you can Login`);
          history.push({pathname: "login"})
          dispatch(userLogout(props.history))    
        }else{
          toast.error(`${res.data.message}`);
        }
      })
      .catch((err) => {
        console.error("err", err);
         toast.error(`failed to update profile`);
      });
    }

  };

  return (
    <div>
      <section className="starting">
        <div className="container-fluid ms-0 me-0">

          <div className="row" style={{ height: "100vh" }}>
            <div
              className="col-lg-5 starting-bg pe-0 d-flex align-items-center justify-content-center py-4 py-lg-0"
              style={{
                backgroundImage: `url(assets/images/background/01.jpg)`,
              }}
            >
              <div className="starting-left">
                <img
                  src="assets/images/logo/logo.png"
                  alt="Logo"
                  className="img-fluid mx-auto d-block"
                />
                <div className="starting-left-text">
                  <h6 className="text-center lead text-secondary">
                    Keep youself manged and
                  </h6>
                  <h6 className="text-center lead text-secondary mb-0">
                    organized
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-7 starting-right sub-pages">
              <div className="starting-right-content">
                <h2 className="text-primary text-center">Complete Profile.</h2>
                <p className="normal-heading mx-auto text-center">
                  You email is verified, complete your profile by adding a
                  username and phone number (optional)
                </p>
                <div className="signup-form">
                  <form  onSubmit={handleSubmit(submitHandler)}>
                    <div className="mb-35">
                      <input
                        {...register("username", { required: true, minLength: 4, maxLength: 30})}
                        type="text"
                        className="form-control custom-input"
                        placeholder="Username*"
                      />
                      {true ? console.log("value of errors :: ",control.register) : console.log("value of errors :: ",control.register)}
                      {/* {errors.username && errors.username.type === "required" && (
                        <span role="alert"> Username is Required ! </span>
                      )}
                      {errors.username && errors.username.type === "maxLength" && (
                        <span role="alert"> Username MaxLength exceeded ! </span>
                      )}
                      {errors.username && errors.username.type === "minLength" && (
                        <span role="alert"> Username MinimumLength is 4 ! </span>
                      )} */}
                    </div>
                    <div className="mb-35">
                      <input
                        {...register("mobile", { required: false, minLength: 6})}
                        type="tel"
                        className="form-control custom-input"
                        placeholder="Mobile no (optional)"
                      />
                    </div>
                    <div className="mb-35">
                      <input
                        {...register("password",{ required: true, minLength: 8, maxLength: 25})}
                        type="password"
                        className="form-control custom-input"
                        placeholder="Password*"
                      />
                      {/* {errors.password && errors.password.type === "required" && (
                        <span role="alert"> Password is Required ! </span>
                      )}
                      {errors.password && errors.password.type === "maxLength" && (
                        <span role="alert"> Password MaxLength exceeded ! </span>
                      )}
                      {errors.password && errors.password.type === "minLength" && (
                        <span role="alert"> Password MinimumLength is 4 ! </span>
                      )} */}
                    </div>
                    <div className="mb-35">
                      <input
                        {...register("cpassword",{ required: true, minLength: 8, maxLength: 25})}
                        type="password"
                        className="form-control custom-input"
                        placeholder="Confirm Password*"
                      />
                      {/* {errors.cpassword && errors.cpassword.type === "required" && (
                        <span role="alert"> Confirm Password is Required ! </span>
                      )}
                      {errors.cpassword && errors.cpassword.type === "maxLength" && (
                        <span role="alert"> Confirm Password MaxLength exceeded ! </span>
                      )}
                      {errors.cpassword && errors.cpassword.type === "minLength" && (
                        <span role="alert"> Confirm Password MinimumLength is 4 ! </span>
                      )} */}
                    </div>
                    <div className="mb-4 form-check">
                      <input
                    {...register("checked")}
                        type="checkbox"
                        className="form-check-input custom-checkbox"
                        id="check"
                      />
                      <label
                        className="form-check-label text-primary fw-500"
                        for="check"
                        style={{
                          maxWidth: " 345px",
                          lineHeight: "29px",
                        }}
                      >
                        I agree to platform’s{" "}
                        <span className="text-info">Terms of service</span> and{" "}
                        <span className="text-info">Privacy policy</span>
                      </label>
                    </div>
                    <div className="d-flex justify-content-end mt-5 submit-area">
                      <button type="submit" className="btn-submit">
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(DetailComponent);
