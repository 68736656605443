import React from 'react'
import SignUpComponent from '../components/Signup/SignUp'

export const SignUpPage = () => {
    return (
        <div>
            <SignUpComponent />
        </div>
    )
}
