import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Icon, Modal, Checkbox, Form, Input, Radio, Select, TextArea} from 'semantic-ui-react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import moment from 'moment'
import 'moment-timezone'

const SelectDate = (props) => {

  const modalCenter = {
    left: 'unset',
    marginTop: '50px',
    height:'53%'
  }
  const modalContent = {
    marginTop: '30px'
  }

  let guessZone = moment.tz.guess();
  console.log("guess zone :: ",guessZone)

    const [open, setOpen] = useState(true);
    const [value, setValue] = useState(0)
    const [reminderDate, setReminderDate] = useState(moment().format());
    console.log("value of reminder date :: ",reminderDate);

    console.log("value of reminder date in sleect date component :: ",reminderDate);
    
    const handleSelectDate = (date) => {
      console.log("date in handle date fucntion in select dtae component :: ",date)
      setReminderDate(moment(date).format())
    }
    console.log("value of selected date in select date component :: ",reminderDate)
    const handleCancel = () => {
      props.setDate('')
      setValue(0)
      props.reminderC(value)
      props.dateSelectToggle(false);
      setOpen(false)
    }
    const handleChange = (e) => {
      console.log("value of e :: ",e);
      setValue(e.target.value)
    }

    const handleSubmit = () => {
      props.setDate(reminderDate)
      props.reminderC(value)
      props.dateSelectToggle(false);
      setOpen(false)
    }

    return ( 
        <Modal
      centered={true}
      size={'mini'}
      closeIcon
      open={open}
      onClose={handleCancel}
      onOpen={() => setOpen(true)}
      style={modalCenter}
    >
         <Modal.Content>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        renderInput={(props) => <TextField {...props} />}
        label="Reminder Date"
        value={reminderDate}
        onChange={handleSelectDate}
        minDateTime={new Date()}
      />
    </LocalizationProvider>
    <br/><br/>
    <Form>
    <Form.Group grouped>
      <label>Reminder Cycle</label>
      <Form.Field
        label='One Time'
        control='input'
        type='radio'
        name='htmlRadios'
        value='0'
        onChange={handleChange}
      />
      <Form.Field
        label='Daily'
        control='input'
        type='radio'
        name='htmlRadios'
        value='1'
        onChange={handleChange}
      />
      <Form.Field
        label='Weekly'
        control='input'
        type='radio'
        name='htmlRadios'
        value='2'
        onChange={handleChange}
      />
      <Form.Field
        label='Monthly'
        control='input'
        type='radio'
        name='htmlRadios'
        value='3'
        onChange={handleChange}
      />
      <Form.Field
        label='Yearly'
        control='input'
        type='radio'
        name='htmlRadios'
        value='4'
        onChange={handleChange}
      />
    </Form.Group>
    </Form> 
        </Modal.Content>
        <Modal.Actions>
        <Button color='red' onClick={handleCancel}>
          <Icon name='remove' />Cancel
        </Button>
        <Button color='green' onClick={handleSubmit}>
          <Icon name='checkmark' />Add Reminder
        </Button>
      </Modal.Actions>
        </Modal>
    )
}

export default (SelectDate);