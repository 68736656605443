import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Icon, Modal } from 'semantic-ui-react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
import axios from 'axios'
import { api_url } from '../../utils/api'

const token = localStorage.getItem("jwtToken");

const config = {
  headers: { Authorization: `Bearer ${token}` }
};

const AddTag = (props) => {
    const [getUsrTags, setUsrtags] = useState([]);

    const [addTagToggle, setAddTag] = useState(false);
    const [newTag, setNewTag] = useState({
        id: '',
        title: ''
      });
    const [tagsValue , setTagsValue] = useState([]);
    const [open, setOpen] = useState(true)


    const animatedComponents = makeAnimated();
    const dispatch = useDispatch();
      console.log("url value")
    //Effects here
    // useEffect(() => {setUsrtags(props.usrTags)},[])

  const handleAddTag = (e) => {
    e.persist();
    setAddTag(!addTagToggle)
  }
  const handleCancelTag = (e) => {
    e.persist();
    setAddTag(!addTagToggle)
    setNewTag((values)=>({
      ...values,
      id:'',
      title:''
    }))
  }

  // let classNameNewTag = {(props.fromEditNote === true) ? (addTagToggle ? <Button onClick={handleCancelTag}>Cancel</Button> : <Button disabled={true} onClick={handleAddTag}>Add new Tag+</Button>) : (addTagToggle ? <Button onClick={handleCancelTag}>Cancel</Button> : <Button onClick={handleAddTag}>Add new Tag+</Button>) }

  // let alreadySelectedTags = props.currentSelected.map((tags,index) => {
  //   console.log("value of selected tags in note :: ",tags.id)
  //   setUsrtags(() => ({
  //     id:tags.id,
  //     value: tags.value
  //   }))
  // })     
  // console.log("selected tags :: ",alreadySelectedTags)


      const handleNewTagValue = (e) => {
        let test = {
          id:'',
          title:e.target.value
        }
        console.log("number of characters in in string :: ",e.target.value.length);
        setNewTag(test)
      }
      console.log("Adding new tag value to newTag state  :: ",newTag);
    
      const deleteTagValue = () => {
        console.log("Inside delete button")
        setAddTag(false)
      }

      console.log("value of tags before options array :: ",props)
      let optionsArr ;
    optionsArr = props.usrTags.map((data,index)=>{
    return {
      value: data.title, 
      label: data.title,
      index: data.id,
      id: data.id,
    }
  });

  let testalready
  if(props.currentSelected.length > 0){
    testalready = props.currentSelected.map((items,index)=>{
    console.log("already selected tags in edit note :: ",items)
    return {
      value: items.value, 
      label: items.value, 
      index: items.id,
      id: items.id
    }
  })
  if(testalready.length > 0) {
    optionsArr.concat(testalready)
    console.log("In if condition for array test :: ",optionsArr)
  }
  else{
    return optionsArr;
  }
}


  // let currentSelectedTags = []
  //  currentSelectedTags = props.usrTags.map((data)=>{
  //   return data.title
  // })
 


  const handleTagsValue = (e) => {
   let tgsValue = e.map((tgvalues, index)=>{
      return{
        id:tgvalues.id,
        value:tgvalues.value,
      }
    })
      setTagsValue(tgsValue)
  }

  console.log("Tags value before close button :: ",tagsValue);
  const getCloseId = (e) => {
    console.log("close button id :: ",e.target);
    let elementId = e.target.id;
    let element = e.target.value;
    console.log("value form close button :: ",element);
    
    let newTagsValue = tagsValue.splice(elementId, 1);
    toast.error(element+" tag removed");
    
    <div className="d-inline-flex" style={{display:'none'}} key={elementId}>{element}</div>

    console.log("Tags Value after Close Button :: ",tagsValue);
  }
  let selectedTagsID
if(tagsValue.length > 0){
  selectedTagsID = tagsValue.map((tag) =>{
    return {
      id: tag.id
    }
  })
}
  
    const tags = JSON.stringify(selectedTagsID);
    console.log("Tags Data :: ",tags);

    const handleTagSubmit = async(e) => {
        console.log("Handle Tag Submit Called :>")
        e.preventDefault();
        
        let tags = newTag
        console.log("value of new tag title :: ",newTag.title == '');

        if(newTag.title != '') {
          console.log("inside first check !!!")
          const TagData = {
            'tags': JSON.stringify([tags]) 
          }

         await axios.post(api_url+'/api/tags',TagData,config)
          .then((response) => {
            toast.success("New Tag Added !!");
            console.log("Success !!!");
            dispatch({
              type: 'NEW_TAG_ADDED',
              payload: response
            })
          })
          .catch((error) => {
            toast.error("Unable to add new Tag !!")
            dispatch({
              type: 'NEW_TAG_FAILED',
              payload: error
            })
          })

          // dispatch(AddNewTag(TagData))

          setNewTag((values)=>({
            ...values,
            id: '',
            title: ''
          }))
          console.log("Value of json stringify for adding tags :: ",tags)
          setAddTag(!addTagToggle)
        }
        else if(newTag.title == ''){
          console.log("inside second check !!!")
          toast.error("Tag Should have a name !!")
          setAddTag(!addTagToggle)
        }
      }

      const handleCancel = ()=>{
        if(props.currentSelected.length > 0 || props.currentSelected.length != ''){
          let previousTags = props.currentSelected.map((item, ind)=>{
            return{
              id: item.id,
              value: item.value
            }
          })
          if(previousTags.length > 0){
            setTagsValue((values)=> ({...values.concat(previousTags)}))
          }
        }
        props.addTagToggle(false);
        setOpen(false)
      }
      const handleUpload = () => {
        if(props.currentSelected.length > 0 || props.currentSelected.length != ''){
          let previousTags = props.currentSelected.map((item, ind)=>{
            return{
              id: item.id,
              value: item.value
            }
          })
          if(previousTags.length > 0){
            setTagsValue((values)=> ([...values.concat(previousTags)]))
          }
        }
        if(tagsValue.length > 0){
          props.selectedTags(tagsValue);
          props.addTagToggle(false);
          setOpen(false)
        }
        else if(tagsValue.length <= 0){
          toast.warn("No Tag Was Selected !!!!")
          setOpen(false)
        }
      }

      const modalCenter = {
        left: 'unset',
        marginTop: '50px',
        height: '75%',
      }
      const modalContent = {
        marginTop: '30px'
      }

      // useEffect(()=>{
      //   if(props.currentSelected.length > 0 || props.currentSelected.length != ''){
      //     let previousTags = props.currentSelected.map((item, ind)=>{
      //       return{
      //         id: item.id,
      //         value: item.value
      //       }
      //     })
      //     if(previousTags.length > 0){
      //       setTagsValue((values)=> ([...values.concat(previousTags)]))
      //     }
      //   }
      // },[])
  

      return (
        <div className="container">
          <Modal
      centered={false}
      size={'mini'}
      closeIcon
      open={open}
      onClose={handleCancel}
      onOpen={() => setOpen(true)}
      style={modalCenter}
    >
      <Modal.Content style={modalContent}>
        <div className="ui raised segments" >
        <div className="ui segment" >
          <Select
          defaultValue={props.currentSelected.map((item,index)=>{
            return{
              value: item.value,
              label: item.value,
              index: item.id,
              id: item.id
            }
          })}
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          options={optionsArr}
          onChange={handleTagsValue}
            />
            
            <br/>
            {(props.fromEditNote === true) ? null : (addTagToggle ? <div>
            <form>
              <input type="text" maxLength={20} onChange={handleNewTagValue} className="form-control" />
              <br/>
              <Button onClick={handleTagSubmit} className="bg-success text-light">Add</Button>
              <Button className="bg-danger" onClick={deleteTagValue}>
                <i className="trash alternate icon container-sm"></i></Button>
            </form><br/>
            </div> : null) }
            
            {(props.fromEditNote === true) ? (addTagToggle ? <Button onClick={handleCancelTag}>Cancel</Button> : <Button disabled={true} onClick={handleAddTag}>Add new Tag+</Button>) : (addTagToggle ? <Button onClick={handleCancelTag}>Cancel</Button> : <Button onClick={handleAddTag}>Add new Tag+</Button>) }
        </div>
        </div>
        </Modal.Content>
        <Modal.Actions>
        <Button color='red' onClick={handleCancel}>
          <Icon name='remove' />Cancel
        </Button>
        <Button color='green' onClick={handleUpload}>
          <Icon name='checkmark' /> Add
        </Button>
      </Modal.Actions>
        </Modal>
        </div>
      )
}


export default(AddTag)
