import React , { useState , useEffect } from 'react'
import { useForm , Controller} from 'react-hook-form';
import qs from 'qs'
import { Link , useHistory } from 'react-router-dom'
import {connect, useDispatch } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify';
import './styles.css';



import { api_url } from '../../utils/api';

import axios from 'axios'
import { RedirectLogin } from '../../utils/common';


import { userLogout } from "../../redux/actions/authActions";

const ResetPasswordComponent = (props) => {
const history = useHistory()
  const { register, handleSubmit, control } = useForm();
  
  const dispatch = useDispatch()
    
  //console.log(props.auth.userData);
  const [Data, setData] = useState([])
  const [TagId, setTagId] = useState()
 const token = props.auth.userData.token;
 const userEmail = props.auth.userData.email

const redirectHandler = () => {
    dispatch(userLogout(props.history))
  history.push("/login")
  
}



//get all the tags data
const submitHandler = (data) => {
  //  event.preventDefault();

  if(data.password == data.newpassword){
    return toast.warn("old password and  new password should not be same")
  }


let finalData = qs.stringify({

password : data.password,
newpassword : data.newpassword
});

let config = {
method: "post",
url: "https://dev7.invitocreatives.com/api/change-password",
headers: {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: `Bearer ${token}`
},
data: finalData,
};


     axios
       (config)
       .then((res) => {
        toast.success(`${res.data.message}`);
        if(res.data.status == true){
          redirectHandler()
        }
      })
       .catch((err) => {
        toast.warn(`${err.data.message}`);
      });
 }


    return (
        <div>
  <section className="starting">
    <div className="container-fluid ms-0 me-0">
      <div className="row" style={{height: "100vh"}}>
        <div className="col-lg-5 starting-bg pe-0 d-flex align-items-center justify-content-center py-4 py-lg-0"
          style={{backgroundImage: `url(assets/images/background/01.jpg)`}}>
          <div className="starting-left">
            <img src="assets/images/logo/logo.png" alt="Logo" className="img-fluid mx-auto d-block" />
            <div className="starting-left-text">
              <h6 className="text-center lead text-secondary">Keep youself manged and</h6>
              <h6 className="text-center lead text-secondary mb-0">organized</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-7 starting-right sub-pages">
          <div className="starting-right-content">
            <h2 className="text-primary text-center">Reset Plassword.</h2>
            <p className="normal-heading text-center mx-auto">
              You email is verified, complete your profile by adding a username and phone number (optional)
            </p>
            <div className="signup-form">
            <form onSubmit={handleSubmit(submitHandler)}>
                <div className="mb-35">
                  <input type="email" value={userEmail} className="form-control custom-input" placeholder="Email" />
                </div>
                <div className="mb-3">
                  <input type="password"
                {...register("password")}

                   className="form-control custom-input" placeholder="Old Password" />
                </div>
                <div className="mb-35">
                  <input type="password"
                           {...register("newpassword")}
                                      className="form-control custom-input" placeholder="New password" />
                </div>
                <div className="mb-35">
                  <input type="password" className="form-control custom-input" placeholder="Confirm Password" />
                </div>
                <div className="d-flex justify-content-end mt-5 submit-area">
                  <Link to="/">
                  <button type="button" style={{marginRight:'20px'}} className="btn-submit cancelBtn">Cancel</button>
                  </Link>
                  <button type="submit" className="btn-submit">Continue</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

        </div>
    )
}


const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(ResetPasswordComponent);