import React from 'react'
import ArchievesComponent from '../components/Archeives/ArchievesCompment'

const ArchievesPage = () => {
    return (
        <div>
            <ArchievesComponent />
        </div>
    )
}

export default ArchievesPage
