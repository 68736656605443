import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import { Button, Checkbox, List, Grid, Segment, TransitionablePortal } from 'semantic-ui-react';
import { connect, useDispatch } from 'react-redux';
import { newNote, getUserTags, AddNewTag, editNote } from '../../redux/actions/authActions';
import Lightbox from "react-image-lightbox";
import moment from 'moment'
import { ToastContainer, toast } from "react-toastify";
import S3FileUpload, { uploadFile, deleteFile } from "react-s3";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ReactQuill from 'react-quill'; 
import FileUpload from '../FileUploadComponent/fileUpload';
import AddTag from '../AddTagComponent/addTag';
import 'react-quill/dist/quill.snow.css';
import SelectDate from '../selectDatecomponent/selectDateComponent';
import AddTask from '../addTaskComponent/addTask';
import Fab from '@mui/material/Fab';
import axios from 'axios'
import { acrhiveNote, deleteNote } from '../../redux/actions/authActions';
import DeleteIcon from '@mui/icons-material/Delete';
import ClockIcon from '../../assets/images/icon/clock.svg';
import SaveIcon from '../../assets/images/icon/save.svg';
import ImageIcon from '../../assets/images/icon/image.svg';
import TickIcon from '../../assets/images/icon/tick.svg';
import BackIcon from '../../assets/images/icon/back.svg';
import LogoIcon from '../../assets/images/icon/logo.svg';
import SearchIcon from '../../assets/images/icon/search.svg';
import UserIcon from '../../assets/images/icon/user.svg';
import ShareIcon from '../../assets/images/icon/share.svg';
import FileIcon from '../../assets/images/icon/file.svg';
import TrashIcon from '../../assets/images/icon/trash.svg';
import SecondHeaderComponent from '../common/header/header2';


const api_url = "https://dev7.invitocreatives.com";
const token = localStorage.getItem("jwtToken");
const configure = {
  headers: { Authorization: `Bearer ${token}` }
};

const config = {
  bucketName: "yellowishs3bucket",
  dirName: "NoteYeti",
  region: "ap-south-1",
  accessKeyId: "AKIAWMQBRPCBP4XTBPTL",
  secretAccessKey: "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"
}

const EditNoteComponent = (props) => {
  const buttonStyle = {
    backgroundColor: '#93D9F8',
    border: '2px solid black',
    padding: '10px',
    margin:'0px 25px',
    height: '40px',
    width: '90px',
    borderRadius: '20px',
    color:'white'
  }

  const imageStyleDiv = {
    height: '200px',
      overflow: 'hidden',
      borderRadius: '10px'
  } 
  const imageStyle = {
    height: '100%',
    width:'auto'
  }
  const buttonDiv = {
    position: 'absolute',
    bottom: '10px',
    right:'10px'
  }
  

  const [getUsrTags, setUsrtags] = useState([]);
  const [values, setValues] = useState({
    title: '',
    description: '',
    QuillLength: ''
  })
  const [checkB, setCheck] = useState(false);
  const [Tasks, setTasks] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [dateselectbtn, setbtnvalue] = useState(false);
  const [tagsBtn, setTagBtn] = useState(false);
  const [tagsValue , setTagsValue] = useState([]);
  let [Fupload,setFupload] = useState(false);
  let [UserFile, setUserFile] = useState([]);
  let [taskIsChecked, setTaskIsChecked] = useState(0)
  const [UserNotes, setUserNotes] = useState([]);
  let [reminderCycle, setReminderCycle] = useState(0)


  let {id} = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  console.log("location :: ",location)
  const history = useHistory()

  const getResult = useCallback(() => {
    dispatch(getUserTags(),[dispatch])
  })

//handle functions for input
  const handleTitleInput = (e) => {
    setValues((values) => ({
      ...values,
      title: e.target.value
    }))
  }

  const handleDescInput = (e,Delta,Source,UnprivilegedEditor) => {
    let editorLength = UnprivilegedEditor.getLength();
    if(editorLength <= 1200){
      setValues((values) => ({
        ...values,
        description: e,
        QuillLength: UnprivilegedEditor.getLength()
      }))
    }else{
      setValues((values)=>({
        ...values,
        QuillLength: UnprivilegedEditor.getLength()
      }))
      toast.error('Notes max length is 1200');
    }
  }

  function deleteFile(e) {
    const s = UserFile.filter((item, index) => index !== e);
    setUserFile(s);
    S3FileUpload
    .deleteFile(UserFile, config)
    .then(response => console.log(response))
    .catch(err => console.error(err))
  }

  const handleCheck = (e) => {
    e.persist();
    setCheck(!checkB);
  }

  function deleteTask(e){
    let remaining = Tasks.filter((item,index)=> index !== e)
    setTasks(remaining);
  }

  const handleCheckbox = (e) => {
    if(taskIsChecked === 0){
      setTaskIsChecked(1)
    }
    else if(taskIsChecked === 1){
      setTaskIsChecked(0)
    }
    let updateTasks = [...Tasks]
    let objectindex = Tasks.findIndex(((obj,index) => index == e));
    let isTaskChecked = taskIsChecked
    updateTasks[objectindex].isChecked = isTaskChecked;
    setTasks(updateTasks);
    console.log("after update of tasks :: ",Tasks);
  }

  const imageList = UserFile.length > 0 &&
  UserFile.map((item, index) => {
    return (
       <div style={{borderRadius:'10px'}} className="card">
      <div style={imageStyleDiv}  key={item} className="image imageStyleDiv">
        <img style={imageStyle} src={item} alt="" />
        
      </div>
      <div style={buttonDiv}>
      <Fab  size="small" onClick={()=>deleteFile(index)} style={{float:'right',backgroundColor:'Crimson'}} color="primary" aria-label="add">
          <DeleteIcon />
        </Fab>
        </div>
      </div>
    );
  })

  const taskList = <List divided verticalAlign='middle' style={{padding:'5px 30px'}}>
  {Tasks.length > 0 && Tasks.map((task,index)=>{
    console.log("tasks :: ",task)
    return(
      <List.Item key={index} >
      <List.Content>
        <Checkbox checked={task.isChecked} label={task.title} onClick={()=>handleCheckbox(index)} />
        <Fab size="small" onClick={()=>deleteTask(index)} style={{background:'unset',boxShadow:'unset'}}>
        <DeleteIcon style={{color:'red'}}/>
        </Fab>
      </List.Content>
    </List.Item>
    )
  })}
</List>

  const selectDate = () => {
    setbtnvalue(!dateselectbtn);
    if(dateselectbtn === false){
    }
  }

//some of handle functions ends here


  //tags toggle button
  const tagbtntoggle = (e) => {
    e.preventDefault();
    setTagBtn(!tagsBtn);
  }
  //toggle for tags ends here -- 

  //adding tags value to state

  const handleTagsValue = (e) => {
   let tgsValue = e.map((tgvalues, index)=>{
      return{
        id:tgvalues.id,
        value:tgvalues.value,
      }
    })
      setTagsValue(tgsValue)
  }
  //adding tags value to state ends here --

  //Remove tags from selected tags

  const getCloseId = params => e => {
    let elementId = e.target.id;
    let element = e.target.value;

    let newTagsValue = tagsValue.filter((tag) => tag.id !== params);
    console.log("elementID :: ",e);
    console.log("value of newTagsValue :: ",newTagsValue)
    setTagsValue(newTagsValue);
    toast.error("Tag removed");

    <div className="d-inline-flex" style={{display:'none'}} key={elementId}>{element}</div>
  }
  //Remove tags from selected tags ends here  --


  //selected from dropdown tags 
  let valuefromTgArr = 
    <Stack direction="row" spacing={1}>
  {tagsValue.length > 0 && tagsValue.map((data,index) => {
      return (
        <Chip
        label={data.value}
        value={data.value}
        key={index}
        variant="outlined" 
        color="info"
        onDelete={getCloseId(data.id)}
      />
      )
    })}
    </Stack>
    //selected from dropdown tags ends here --

    const ArchiveNote = param => e => {
      const ids = {
        'ids': [param]
      }

      var config = {
        method: 'put',
        url: api_url+'/api/archive',
        headers: { 
          'Authorization': `Bearer ${token}`
        },
        data : ids
      };

      axios(config).then((response) => {
        console.log("Response from archive api :: ",response);
        console.log("api success")
        dispatch({
          type: 'ARCHIVE_NOTE_SUCCESS',
          payload: response
        })
        toast.success("Note Archived !!");
        history.push({pathname:'/'})
      })
      .catch((err)=>{
        //console.log("Something went wrong in archive note api !!!");
        console.log("api error")
        dispatch({
          type: 'ARCHIVE_NOTE_FAIL',
          payload: err
        })
      })


      // dispatch(acrhiveNote(ids,from))

      // console.log("note archived !!",param);
    }

    const delNote = param => e => {
      const ids = {
        'ids': [param]
      }

      var config = {
        method: 'delete',
        url: 'https://dev7.invitocreatives.com/api/notes',
        headers: { 
          'Authorization': `Bearer ${token}`
        },
        data : ids
      };

      axios(config)
  .then(function (response) {
    toast.success("Note Deleted !!!")    
    dispatch({
      type:"DELETE_NOTE",
      payload: response
    })
    history.push({pathname:'/'})
  })
  .catch(function (error) {
    toast.error("SomeThing went wrong !!!")
    dispatch({
      type:"DELETE_NOTE_FAIL",
      payload: error
    })
  });

      // dispatch(deleteNote(ids))
      // toast.error("Note Deleted !!");
      // history.push({pathname:'/'})
    }

    //tags in state 
    const selectedTagsID = tagsValue.length > 0 && tagsValue.map((tag) =>{
      return {
        id: tag.id
      }
    })
    let tags = JSON.stringify(selectedTagsID);
    console.log("tags :: ",tags);

    //tasg in state ends here


  //file upload switch 
  let FuploadBtn = (e) => {
    e.preventDefault();
    setFupload(!Fupload)
  }
  //file upload switch ends here -- 

  let selectedImages = UserFile.map((item)=>{
    return{
      url: item
    }
  })

  let images = JSON.stringify(selectedImages)


  let userAddedTasks = Tasks.map((value)=>{
    return{
      title:value.title,
      ischecked:value.isChecked
    }
  })

  let tasks = JSON.stringify(userAddedTasks)


    //data sent
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("value of e in sumit handler :: ",e);

    let reminder = '';

    if(startDate != ''){
      // console.log("value of start date :: ",startDate)
      // console.log("value of start date split :: ",startDate.split('T'))
      let startDAteTEstHere = startDate.split('T')
      // console.log("value of startdate split :: ",startDAteTEstHere[0]," Something :: ",startDAteTEstHere[1])
      let dateHere = startDAteTEstHere[0];
      let timeHere = startDAteTEstHere[1];
      if(startDAteTEstHere.length > 0){
        reminder = '[{"date":"'+dateHere+'","time":"'+timeHere+'","cycle":"'+reminderCycle+'"}]';
        // console.log("value of reminder after submit :: ",reminder)
      }
    }
    else{
      reminder = ''
    }

    if(values.title == '' || values.title == null){
      toast.error("Title is Required !!!");
      // console.log("length of quill length :: ",values.QuillLength)
    }
    else if(values.QuillLength > 1200){
      // console.log("length of quill length :: ",values.QuillLength)
      toast.error("Note Description Length should be less than 1200 words !!!");
    }
    else if(values.QuillLength <= 1200){
      // console.log("length of quill length :: ",values.QuillLength)
      if(values.title != ''){
        const noteData = {
          ...values,
          reminder,
          images,
          tags,
          tasks,
          id
        }
        dispatch(editNote(noteData));
        console.log("tags :: ",noteData);
        setValues(()=>({
          title: '',
          description: ''
        }))
        setStartDate('')
        setUserFile([])
        reminder = ''
        images = ''
        setTagsValue([])
        tags = ''
        setTasks([])
        tasks = ''
    

        setTimeout(()=>{
          history.push({pathname: '/'})
        },200)
      }
      else{
        toast.error("Note Description Length should be less than 1200 words !!!");
      }
    }    
  }

  

  useEffect(() => {

    console.log("props data :: ",props)
    if(token == 'undefined'){
      // console.log("inside first token check !!!")
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
    }
     else if(token == null){
      // console.log("inside second token check !!!")
  
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
     } else if(token == undefined){
      // console.log("inside third token check !!!")
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
    }
    else if(token == ''){
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname:'/login'})
    }

    if(location.state != undefined){
      getResult();
      setUsrtags(props.auth.tags)
      setUserNotes(location.state.NoteData)
      setValues((values)=>({
        ...values,
        title: location.state.NoteData.n_title,
        description: location.state.NoteData.n_description
      }))
  
        setUserFile(location.state.NoteData.notes_images.map((item,index)=>{
          return item.ni_url
        }))
      
        setTasks(location.state.NoteData.notes_tasks.map((item,index)=>{
          return {
            title: item.nta_title,
            isChecked: item.nta_ischecked
          }
        }))
      
        setTagsValue(location.state.NoteData.notes_tags.map((item,index)=>{
          return {
            id: item.nt_uuid,
            value: item.tag.tg_title
          }
        }))
  
        if( location.state.NoteData.n_reminder != null){
          // console.log("reminder date :: ",location.state.NoteData.n_reminder)
  
          let testsplit = {}
                  try {
                     testsplit = JSON.parse(location.state.NoteData.n_reminder)
                      let cycle = testsplit[0].cycle
                  let date = testsplit[0].date
                  let time = testsplit[0].time
                  let dateandtime = date+ 'T' +time
                  setStartDate(dateandtime)
                  setReminderCycle(cycle)
                  // console.log("value of dateandtime :: ",dateandtime)
                  } catch (error) {
                    toast.error('Data Not parsed');
                  }
        }
    }

    else if(location.state == undefined || location.state.NoteData == undefined){
      toast.error("unable to fetch data !!!")
    }
    console.log("how many times will this run ");
  },[])
  // console.log("value of select date outside useEffect :: ",startDate)

  
    return (
        <div>

             {/* <!-- Header Starts Here --> */}
<SecondHeaderComponent>
  {/* <!-- Header Ends Here --> */}

  {/* <!-- Note Area Starts Here --> */}
  <section className="note-area">
    <div className="container">
            {tagsBtn && <AddTag fromEditNote={true} currentSelected={tagsValue} addTagToggle={setTagBtn} usrTags={getUsrTags} selectedTags={setTagsValue} />}
            {Fupload && <FileUpload fUploadToggle={setFupload} setFiles={setUserFile} userFiles={UserFile}/>}
            {dateselectbtn && <SelectDate reminderC={setReminderCycle} dateSelectToggle={setbtnvalue} setDate={setStartDate} currentDate={startDate} /> }
            {checkB && <AddTask addTaskToggle={setCheck} taskChecked={taskIsChecked} addNewTask={setTasks} addedTasks={Tasks} />}
      {EditNoteComponent ? <div className="row" style={{marginTop:'40px',marginBottom:'10px'}}>
        <div className="col-lg-12" style={{display:'flex',justifyContent:'flex-end'}}>
          <div className="note-area-heading">
            <div className="mt-0 mt-lg-4">
              
              {/* <!-- <span className="sub-heading">Desciption</span> --> */}
            </div>
            <div className="note-area-right d-flex align-items-center mt-0">
              <button type="button" onClick={()=> history.push({pathname:'/'})} className="btn">
                <img src={ShareIcon} alt="Icon" className="img-fluid" />
                <div className="tooltip-text">
                  <span>Share</span>
                </div>
              </button>
              <button type="button" onClick={ArchiveNote(id)} className="btn">
                <img src={FileIcon} alt="Icon" className="img-fluid" /> 
                <div className="tooltip-text">
                  <span>Archive</span>
                </div>
              </button>
              <button type="button" onClick={delNote(id)} className="btn">
                <img src={TrashIcon} alt="Icon" className="img-fluid" />
                <div className="tooltip-text">
                  <span>Delete</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      : null 
      }
      <div className="row note-area-content">
      <div>
      {startDate !== '' ? <> <img src="assets/images/icon/clock.svg" alt="Icon" style={{height:'30px',width:'30px'}} className="img-fluid" /> <span style={{Left: '25px'}}>Reminder Set For - {moment(startDate).format('LL')}</span> </> : null}
        {valuefromTgArr}
        {UserNotes.length > 0 ? <div className="col-lg-12">
        <form action="#" className="title-text">
                <textarea
                 placeholder="Title"
                  name="Title"
                   value={values.title}
                    className="form-control"
                    onChange={handleTitleInput}
                    maxLength="30"
                    >
                      </textarea>
                    
              </form>
          <form action="#" className="desciption-text">
          <ReactQuill
             placeholder="Desciption"
              name="Description"
              value={values.description}
                className="form-control"
                onChange={handleDescInput}
                />
          </form>
        </div> : <div className="col-lg-12">
        <form action="#" className="title-text">
                <textarea
                 placeholder="Title"
                  name="Title"
                   value={values.title}
                    className="form-control"
                    onChange={handleTitleInput}
                    maxLength="30"
                    >
                      </textarea>
                    
              </form>
          <form action="#" className="desciption-text">
          <ReactQuill
             placeholder="Desciption"
              name="Description"
               value={values.description}
                className="form-control"
                onChange={handleDescInput}
                />
          </form>
        </div> }
        </div> 
        {taskList}
                <div className="ui six doubling cards">
                  {imageList}
                </div>
          </div>
          
      <div className="row" style={{marginTop:'20px'}}>
        <div className="col-lg-12">
          <div className="note-area-footer d-flex align-items-center">
            <button type="button" onClick={handleCheck} className="btn"><img src={TickIcon} alt="Icon" className="img-fluid" /></button>
            
            <button type="button" onClick={FuploadBtn} className="btn"><img src={ImageIcon} alt="Icon" className="img-fluid" />
            </button>
            <button type="button" className="btn"  onClick={tagbtntoggle}><img src={SaveIcon} alt="Icon" className="img-fluid" /></button>
            <button type="button" className="btn" onClick={selectDate}>
              <img src={ClockIcon} alt="Icon" className="img-fluid" />
            </button>
            <Button type="button" className="btn" style={buttonStyle} onClick={handleSubmit}>UPDATE</Button>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* <!-- Note Area Ends Here --> */}
  </SecondHeaderComponent>
        </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(EditNoteComponent);
