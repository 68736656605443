import React from 'react'
import HomePageComponent from '../components/HomePage/HomePageComponent'

const HomePage = () => {
    return (
        <div>
            <HomePageComponent />
        </div>
    )
}

export default HomePage
