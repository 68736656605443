import React  , { useState , useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { api_url } from '../../utils/api';
import { getUserTags } from '../../redux/actions/authActions';
import axios from 'axios';
import moment from 'moment'
import 'moment-timezone'

import {Button, Checkbox, Segment, Loader, Dimmer, Grid, TransitionablePortal} from 'semantic-ui-react';

import { connect , useDispatch } from 'react-redux';
import SecondHeaderComponent from '../common/header/header2'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './style.css';

const token = localStorage.getItem("jwtToken");
const configure = {
  headers: { Authorization: `Bearer ${token}` }
};


const TagsComponent = (props) => {

  const userName = props.auth.userData.username;
  let history = useHistory()

  const [Data, setData] = useState([])
  const [Reminders, setReminders] = useState([])

  const token = props.auth.userData.token;


 const colorWhite = {
   color: 'white',
   backgroundColor: '#93D9F8'
 }


 const JWTtoken = localStorage.getItem("jwtToken");
 var config = {
  method: 'get',
  url: `${api_url}/api/tags`,
  headers: { 
    'Authorization': `Bearer ${JWTtoken}`
  },
};


//get all the tags data
 const getTagsData = () => {
  axios(config)
  .then(function (response) {
setData(response.data.payload)
  })
  .catch(function (error) {
  });
  
 }

  useEffect(() => {

    if(token == 'undefined'){
      console.log("inside first token check !!!")
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
    }
     else if(token == null){
      console.log("inside second token check !!!")
  
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
     } else if(token == undefined){
      console.log("inside third token check !!!")
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname: '/login'})
    }
    else if(token == ''){
      toast.error("UnAuthorized User !! Please Login First!");
      history.push({pathname:'/login'})
    }

  getTagsData()
  // axios.post(api_url+'/api/notes-list',{isarchive : '0'},config)
  // .then((response)=>{
  //   if(response){
  //     setReminders(response.data.payload.data.filter((notes)=> notes.n_reminder != null))
  //   }
  // })
  // .catch((error)=>{
  //   console.log("error :: ",error)
  // })

  // let guessZone = moment.tz.guess();
  // let customDate = '2021-12-12T12:00'
  // let test = moment(customDate).tz(guessZone).format('ha z')
  // console.log("guess time zone :: ",test)

  
}, [])
// console.log("reminders :: ",Reminders)



const deleteHandler = (tagId) => {
  let testArray = [];
  testArray = [...Data]
  setData(testArray.filter((item)=> item.id !== tagId
   ))
  console.log("tags id :: ",tagId)
      axios
        .delete(`${api_url}/api/tags/${tagId}`, {
          headers: { 'Authorization': `Bearer ${token}` },
        })
        .then((res) => {
          //console.log(res);
             toast.success("Tag successfully deleted !!");
        })
        .catch((err) => {
          setData(Data)
          toast.error("Can not delete this TAG because it is used in Notes !!!");
        });
};




    return (
      <div>
        {/* <!-- Header Starts Here --> */}
        <SecondHeaderComponent>

        <section className="note-area manage-tags" style={{marginTop:'25px'}}>
          <div className="container">
            <div
              className="row user-profile justify-content-center"
              style={{
                backgroundImage: `url(assets/images/background/02.jpg)`,
              }}
            >
              <div className="manage-tags-area">
                <h4>Manage tags</h4>
              </div>

              <div className="col-lg-12">
                <div className="row justify-content-between">
                  
                    {Data.map((tag, tagindx) => {
                      return (
                        <div className="col-md-4">
                        <div key={tag.tag_id} className="tags-wizerd">
                          <div className="tags-wizerd-left">
                            <h6>{tag.title}</h6>
                          </div>
                          <div style={{display:'flex',justifyContent:'flex-end',marginTop:'-30px'}}>
                          <button
                            className="btn"
                            type="button"
                            onClick={() => {
                              const tagId = tag.id;
                              deleteHandler(tagId);
                            }}
                          >
                            <img
                              src="assets/images/icon/trash-02.svg"
                              alt="Icon"
                              className="img-fluid"
                            />
                          </button>
                          </div>
                        </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="manage-tags-bottom">
                  <div className="text-center text-md-end text-lg-end">
                    <Link style={colorWhite} to="/newNote" className="btn accent-btn-sm">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24 12C24 13.106 23.904 14 22.798 14H14V22.798C14 23.902 13.106 24 12 24C10.894 24 10 23.902 10 22.798V14H1.202C0.0979997 14 0 13.106 0 12C0 10.894 0.0979997 10 1.202 10H10V1.202C10 0.0959997 10.894 0 12 0C13.106 0 14 0.0959997 14 1.202V10H22.798C23.904 10 24 10.894 24 12Z"
                          fill="#1B2330"
                        />
                      </svg>
                      Add new
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </SecondHeaderComponent>
      </div>
    );
}

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(TagsComponent);







