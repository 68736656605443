import React ,{ useState , useEffect } from 'react'
import { Link , useHistory } from 'react-router-dom'

import axios from "axios"
import qs from "qs"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useForm  , Controller , useFormState , useWatch } from "react-hook-form";
import { api_url } from '../../utils/api';
import { userSignup } from '../../redux/actions/authActions';
import { useDispatch } from 'react-redux';



const SignUpComponent = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const redirectHandler = () => {
    setTimeout(() => {
    history.push("/detail")
    }, 5000);
    
  }
  
  let otpBaba;

  let childRender = 0;

const [childState , setChildState ] = useState()
      const { register, handleSubmit, control } = useForm();
      const { isDirty } = useFormState({
        control
      }); 
    let emailwatcher;
      function FirstNameWatched({ control }) {
     const nameWatcher =   useWatch({
          control,
          name: "email" // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        });
      emailwatcher = nameWatcher;
      
        return (
          <>
          </>
        );
      }

     
      const [signupId, setsignupId] = useState()

      const [emailIDHandler, setemailIDHandler] = useState()

      const [isTouchedEmail, setisTouchedEmail] = useState()


        const submitHandler = (data) => {
          //  event.preventDefault();
       
            
       if( data.checked == false){
         return toast.warn(`kindly agree to the terms & conditions !`)
       }

      let finalData = qs.stringify({
        id: signupId,
        otp: data.otp,
        email: data.email,
        device_id: "fasdf345345345",
        device_token: "asfasdfasdfdas",
        device_platform: "IOS",
      });

      let config = {
        method: "post",
        url: "https://dev7.invitocreatives.com/api/verify-otp",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: finalData,
      };


        // //console.log("user =>" , user)
       ////console.log("id", signuREpId)

      axios
        (config)
        .then((res) => {
          if(res.data.status == true){
            localStorage.setItem("email", res.data.payload.email);
            dispatch(userSignup(res.data.payload, props.history));
            //  redirectHandler()\
            toast.success(`Your email is verified | Please set your password`);
            history.push({pathname:'/detail'})
          }else{
            toast.error(res.data.message);
            // history.push({pathname:'/detail'})
          }
        })
        .catch((err) => {
          console.error("err", err);
          toast.warn(`${ "Signup failed"}`);
        });
        }


        const emailOtpHandler =(email) => {
          if(!email){
           return toast.error(`please enter email`);
          }
          //console.log("data", email)

           otpBaba = email
            
         setemailIDHandler(otpBaba)
     if(email){
      
      const data = { email: emailwatcher };

    //email change handler
 
   if(emailwatcher != emailIDHandler ){
    const user = {
      ...data
    };
    
    //console.log("user => ", user);
    
    
     axios
.post(`${api_url}/api/signup`, data)
.then((res) => {
  //console.log("responsesignup",res);
  toast.success(`${res.data.message}`);
  setsignupId(res.data.payload.id);

})
.catch((err) => {
  console.error("Err", err.message);
  toast.warn(`${err.data.message || " something went wrong"}`);
});

   }

     }
         
        }

    return (
      <div>
        <section classNameName="starting">
          <div className="container-fluid ms-0 me-0">
          <FirstNameWatched control={control} />

            <div className="row" style={{ height: "100vh" }}>
              <div
                className="col-lg-5 starting-bg pe-0 d-flex align-items-center justify-content-center py-4 py-lg-0"
                style={{
                  backgroundimage: `url(assets/images/background/01.jpg)`,
                }}
              >
                <div className="starting-left">
                  <img
                    src="assets/images/logo/logo.png"
                    alt="Logo"
                    className="img-fluid mx-auto d-block"
                  />
                  <div className="starting-left-text">
                    <h6 className="text-center lead text-secondary">
                      Keep youself manged and
                    </h6>
                    <h6 className="text-center lead text-secondary mb-0">
                      organized
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 starting-right">
                <div className="starting-right-content">
                  <h2 className="text-primary text-center">Get Started.</h2>
                  <p className="text-primary sub-heading mb-0 text-center">
                    Already have an account?{" "}
                    <Link to="/login" className="text-info">
                      Login
                    </Link>
                  </p>
                  <div className="signup-form reg-info">
                    <form onSubmit={handleSubmit(submitHandler)}>
                      <div className="mb-35">
                        <Controller
                          control={control}
                          name="email"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <input
                              type="email"
                              {...register("email" , {
                                required: "required",
                                pattern: {
                                  value: /\S+@\S+\.\S+/,
                                  message: "Entered value does not match email format"
                                }})}
                              className="form-control custom-input"
                              placeholder="Email*"
                              // onChange={(e) =>
                              //   //console.log("eee", e.target.value)
                              // }
                              onBlur={(e) => {
                                const email = e.target.value;
                                emailOtpHandler(email);
                              }}
                              onChangeCapture={

                                    (e) => {
                                      let val = e.target.value;
                                    }
                              }
                            />
                          )}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          {...register("otp")}
                          type="text"
                          className="form-control custom-input"
                          placeholder="OTP"
                          
                        />
                        <div className="mt-2 mt-lg-3 form-text text-info text-end me-4" onClick={emailOtpHandler} >
                          Resend OTP 
                        </div>
                      </div>

                      <div className="mb-4 form-check">
                        <input
                       {...register("checked")}
                          type="checkbox"
                          className="form-check-input custom-checkbox"
                          id="check"
                        />
                        <label
                          className="form-check-label text-primary fw-500"
                          for="check"
                          style={{ maxWidth: "345px", lineHeight: "29px" }}
                        >
                          I agree to platform’s{" "}
                          <span className="text-info">Terms of service</span>{" "}
                          and <span className="text-info">Privacy policy</span>
                        </label>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button type="submit" className="btn-submit">
                          Register
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default SignUpComponent