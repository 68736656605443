import React, { useEffect, useState } from  'react'
import { useDispatch , connect} from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { Button, Checkbox, List, Grid, Segment, TransitionablePortal } from 'semantic-ui-react';
import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import { userLogout } from '../../../redux/actions/authActions';
import BackIcon from '../../../assets/images/icon/back.svg';
import LogoIcon from '../../../assets/images/icon/logo.svg';
import SearchIcon from '../../../assets/images/icon/search.svg';
import UserIcon from '../../../assets/images/icon/user.svg';
import ClockIcon from '../../../assets/images/icon/clock.svg';
import ReminderComponent from '../../ReminderNotes/reminderNotes'

const api_url = "https://dev7.invitocreatives.com";
const token = localStorage.getItem("jwtToken");
const configure = {
  headers: { Authorization: `Bearer ${token}` }
};


const SecondHeaderComponent = ({ children: children, ...props }) => {
 
    let [IsOpen, setIsOpen] = useState(false)
    let [Reminders, setReminders] = useState([])
    let [UserNotes, setUserNotes] = useState([])

    let username = props.auth.userData.username;

let history = useHistory()

    const handleOpen = () => {
        setIsOpen(true)
      }
       const handleClose = () => {
        setIsOpen(false)
      }

      const handleShowReminder = () => {
        history.push("/reminderNotes")
      }


      useEffect(() => {
        // getResult();
        // setUsrtags(props.auth.tags)

        console.log("value of URL :: ",window.location.href)
    
          axios.post(api_url+'/api/notes-list',{isarchive : '0'},configure)
          .then((response)=>{
            setReminders(response.data.payload.data.filter((notes) => notes.n_reminder != null))
          })
          .catch((error)=>{
            console.log("Error Occured :: ",error);
          })
        
        console.log("how many times will this run ");
      },[])



return (
    <React.Fragment>
    <div>
<header>
<div className="container-fluid second-header">
  <nav className="navbar navbar-expand-lg navbar-light">
    <div className="container-fluid">
      <Link className="navbar-brand back-logo" to="/">
          <>
        <img src={BackIcon} alt="Back Icon" className="img-fluid me-4" />
        <img src={LogoIcon} alt="Logo" />
        </>
      </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
          {/* <li className="last-item nav-item">
            <div className="search-box">
              <form action="#">
                <input type="search" id="search" placeholder="Search" className="form-control" />
                <button className="btn"><img src={SearchIcon} alt="Search" /></button>
              </form>
            </div>
          </li> */}
         {(props.hideClock === true) ? null : <li className="nav-item">
             <div className="notification">
               <button type="button" onClick={() => handleOpen()} className="btn">
                 <img src={ClockIcon} alt="Clock" className="img-fluid" />
               </button>
             </div>
          </li> } 
          <li className="nav-item">
            <Link className="nav-link avatar-area d-flex align-items-center justify-content-center" to="/user">
              <div className="avatar">
                <img src={UserIcon} alt="User" className="img-fluid" />
              </div>
              <span>{username}</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
</header>
<main>
{(props.hideClock === true) ? null : <Grid style={{marginTop: '-40px'}} className="ParentContainer" columns={2}>
        <Grid.Column>
          <TransitionablePortal
            open={IsOpen}
            onClose={handleClose}
          >
            <Segment
            className="reminderList"
            >
              {Reminders.length > 0 ? <>
              {Reminders.map((notes,index)=>{ 
                console.log(index,notes)
                let testsplit = {}
                try {
                   testsplit = JSON.parse(notes.n_reminder)
                    let cycle = testsplit[0].cycle
                let date = testsplit[0].date
                let time = testsplit[0].time
                let dateandtime = date+ 'T' +time
                if(index <= 2){
                return (
                <div style={{width:'250px',borderBottom: '0.2px solid #00000033',marginBottom: '2px', padding: '5px 0px'}}>
                <p style={{fontSize:'12px'}}>{moment(dateandtime).format('MMMM Do YYYY, h:mm a')}</p>
                <p style={{marginTop:'-10px',fontSize:'22px'}}><b>{notes.n_title}</b></p>
                </div>
              )
                }
                } catch (error) {
                  toast.error('Data Not parsed');
                }
             })}
                </> : <p>No Reminders</p>}
                <Button inverted color="blue" onClick={()=> handleShowReminder()} className="input-group">Show more</Button>
            </Segment>
          </TransitionablePortal>
        </Grid.Column>
      </Grid>}
    {children}
</main>
</div>
</React.Fragment>
)
}

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(SecondHeaderComponent)