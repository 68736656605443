import React from 'react'
import EmptyScreenCopmponent from '../components/EmptyScreenComp/EmptyScreenCopmponent'

const EmptyScreenPage = () => {
    return (
        <div>
            <EmptyScreenCopmponent />
        </div>
    )
}

export default EmptyScreenPage
