import React from 'react'
import EditNoteComponent from '../components/EditNote/EditNoteComponent';

const NewNotePage = () => {
    return (
        <div>
            <EditNoteComponent />
        </div>
    )
}

export default NewNotePage
