export const IS_LOADING = "IS_LOADING";
export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const USER_PROFILE_DATA = "USER_PROFILE_DATA";
export const USER_SIGNUP = "USER_SIGNUP";
export const NEW_NOTE = "NEW_NOTE";
export const NEW_NOTE_FAIL = "NEW_NOTE_FAIL";
export const USER_TAGS = "USER_TAGS";
export const USER_TAGS_FAIL = "USER_TAGS_FAIL";
export const NEW_TAG_ADDED = "NEW_TAG_ADDED";
export const NEW_TAG_FAILED = "NEW_TAG_FAILED";
export const NOTES_LIST = "NOTES_LIST";
export const NOTES_LIST_FAILED = "NOTES_LIST_FAILED";
export const DELETE_NOTE = 'DELETE_NOTE';
export const DELETE_NOTE_FAILED = "DELETE_NOTE_FAILED";
export const TAG_NOTES_LIST = "TAG_NOTES_LIST";
export const TAG_NOTES_LIST_FAILED = "TAG_NOTES_LIST_FAILED";
export const ARCHIVE_NOTE_SUCCESS = "ARCHIVE_NOTE_SUCCESS";
export const ARCHIVE_NOTE_FAIL = "ARCHIVE_NOTE_FAIL";
export const RESET = "RESET";
export const UPDATE_PROFILE = 'UPDATE_PROFILE';