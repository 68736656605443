import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import {Button, Checkbox, Segment, Loader, Dimmer, Grid, TransitionablePortal} from 'semantic-ui-react';
import { api_url } from '../../utils/api';
import moment from 'moment'
import SecondHeaderComponent from '../common/header/header2'

import { useForm  , Controller } from "react-hook-form";

import { connect } from 'react-redux';
import { updateProfile } from '../../redux/actions/authActions';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const token = localStorage.getItem("jwtToken");
const configure = {
  headers: { Authorization: `Bearer ${token}` }
};

const UserComponent = (props) => {
    
  const userEmail = props.auth.userData.email;
  const userNumber = props.auth.userData.mobile;
  const userName = props.auth.userData.username;
  const dispatch = useDispatch();
  let history = useHistory()


  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      "email" : userEmail,
      "mobile" : userNumber,
      "username" : userName
    },
  });


 const submitHandler = (data) => {
  dispatch(updateProfile(data))
 }

 const handleShowReminder = () => {
   history.push("/reminderNotes")
 }


 useEffect(() => {

  if(token == 'undefined'){
    console.log("inside first token check !!!")
    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname: '/login'})
  }
   else if(token == null){
    console.log("inside second token check !!!")

    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname: '/login'})
   } else if(token == undefined){
    console.log("inside third token check !!!")
    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname: '/login'})
  }
  else if(token == ''){
    toast.error("UnAuthorized User !! Please Login First!");
    history.push({pathname:'/login'})
  }
 },[])

    return (
        <div>
              {/* <!-- Header Starts Here --> */}
              <SecondHeaderComponent>
  {/*  <-- Header Ends Here --> */}
  
  {/* <-- Note Area Starts Here --> */}
  <section 
            className="note-area mt-0" style={{
                backgroundImage: `url(assets/images/background/02.jpg)`,
              }}
              >
    <div className="container">
      <div className="row user-profile justify-content-center">
        <div className="col-lg-8">
          <div className="user-profile-area">
            <div className="d-flex justify-content-center">
              <div className="user-icon">
                <img src="assets/images/icon/userlg.svg" alt="Icon" className="img-fluid" />
              </div>
            </div>
            <h4 className="text-center">Edit profile</h4>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="user-field">
                <input type="text"
           {...register("username")}

                 className="form-control" 
                 placeholder="" />
              </div>
              <div className="user-field">
                <input type="email"
                 className="form-control"
                      {...register("email")}

                 className="form-control" />
              </div>
              <div className="user-field">
                <input type="text"
                {...register("mobile")}

                 className="form-control" 
                 placeholder=" UnAble to fetch Mobile Number." />
              </div>
              <div className="d-flex justify-content-center">
                  <button className="btn accent-btn text-white" type="submit">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* <!-- Note Area Ends Here --> */}
  </SecondHeaderComponent>
        </div>
    )
}


const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(UserComponent);
